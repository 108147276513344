import React, { createContext, useState, useContext } from 'react';

type DeepLinkContextType = {
  deepLinkUrl: string;
  setDeepLinkUrl: (url: string) => void;
};

const DeepLinkContext = createContext<DeepLinkContextType>({
  deepLinkUrl: '',
  setDeepLinkUrl: () => {},
});

export const useDeepLink = () => useContext(DeepLinkContext);

export const DeepLinkProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [deepLinkUrl, setDeepLinkUrl] = useState('');

  return (
    <DeepLinkContext.Provider value={{ deepLinkUrl, setDeepLinkUrl }}>
      {children}
    </DeepLinkContext.Provider>
  );
};
67