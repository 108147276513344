import React, { useState, useRef, useEffect } from 'react';
import { Dimensions, View, StyleSheet, TouchableOpacity, PanResponder, GestureResponderEvent, PanResponderGestureState, TouchableWithoutFeedback } from 'react-native';
import Scrubber from 'react-native-scrubber';
import { Video } from 'expo-av';

import Image from '../UtilityComponents/UniversalImage';
import styles from './style';

type Props = {
  videoDuration: number;
  currentPlaybackTime: number;
  onTimestampSelected: (timestamp: number) => void;
  videoFinished: boolean;
  videoRef: React.RefObject<Video>; // Add this line
};

import { API_URL } from '@env';

const SelectClipTimestamp: React.FC<Props> = ({ videoDuration, currentPlaybackTime, onTimestampSelected, videoFinished, videoRef  }) => {

  const [containerX, setContainerX] = useState(0);
  const [thumbnailCount, setThumbnailCount] = useState(0);
  const [scrubberValue, setScrubberValue] = useState<number>(0);

  useEffect(() => {
    // Update scrubber value to current playback time
    if (!videoFinished) {
      setScrubberValue(currentPlaybackTime);
    } else {
      // Reset scrubber value when video ends
      setScrubberValue(0);
    }
  }, [currentPlaybackTime, videoFinished, videoDuration]);

  const handleContainerLayout = (event: any) => {
    const layout = event.nativeEvent.layout;
    setContainerX(layout.x);
  };

  async function extractFramesFromVideo(videoURI: string | null, frameCount: number): Promise<string[]> {
    try {
        const response = await fetch(`${API_URL}/extract-frames`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ videoURI, frameNumber: frameCount })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.frames;
    } catch (error) {
        console.error('Error extracting frames:', error);
        throw error;
    }
}

  const screenWidth = Dimensions.get('window').width;
  const thumbnailWidth = screenWidth / thumbnailCount;

  useEffect(() => {
    if (videoFinished) {
      onTimestampSelected(0); // Reset the timestamp as well
    } else {
      const newPosition = (currentPlaybackTime / videoDuration) * screenWidth;
      if (!isNaN(newPosition)) {
      }
    }
  }, [currentPlaybackTime, videoDuration, videoFinished]);
  
  const dynamicStyles = StyleSheet.create({
    thumbnail: {
      width: thumbnailWidth,
      height: 50,
    },
  })

  const initialTouchX = useRef(0);

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: (_, gestureState) => {
        // Set some threshold to distinguish between a drag and a tap
        const { dx, dy } = gestureState;
        return dx > 2 || dx < -2 || dy > 2 || dy < -2;
      },
      onPanResponderGrant: (evt) => {
        // When the user starts dragging, capture the initial touch position
        const touchX = evt.nativeEvent.pageX;
        // Calculate the initial touch position relative to the screen width and video duration
        initialTouchX.current = (touchX - containerX) / screenWidth * videoDuration;
      },
      onPanResponderMove: (evt, gestureState) => {
        // Calculate new position based on the initial touch and current movement
        const newScrubberValue = initialTouchX.current + (gestureState.dx / screenWidth * videoDuration);
        // Clamp the value between 0 and the video duration
        const clampedValue = Math.max(0, Math.min(newScrubberValue, videoDuration));
        setScrubberValue(clampedValue);
        onTimestampSelected(clampedValue);
      },
      onPanResponderRelease: (evt, gestureState) => {
        // Finalize the seek position when the user releases the scrubber
        const finalValue = initialTouchX.current + (gestureState.dx / screenWidth * videoDuration);
        const clampedValue = Math.max(0, Math.min(finalValue, videoDuration));
        setScrubberValue(clampedValue);
        onTimestampSelected(clampedValue);
        if (videoRef.current) {
          videoRef.current.setPositionAsync(clampedValue * 1000);
        }
      },
    })
  ).current;

  const getScrubberValueFromGesture = (gestureState: PanResponderGestureState) => {
    const { dx } = gestureState;
    const newScrubberPosition = Math.max(0, Math.min(screenWidth, initialTouchX.current + dx));
    return (newScrubberPosition / screenWidth) * videoDuration;
  };

  const getScrubberValueFromPosition = (touchX: number) => {
    const position = touchX - containerX; // Adjust based on the actual position of the container
    const newScrubberValue = (position / screenWidth) * videoDuration;
    return newScrubberValue;
  };

  const handleContainerTouch = (event: GestureResponderEvent) => {
    const containerPositionX = event.nativeEvent.pageX - containerX; // Subtract containerX to get the correct position within the container
    const selectedTimestamp = (containerPositionX / screenWidth) * videoDuration;
  
    if (!isNaN(selectedTimestamp)) {
      setScrubberValue(selectedTimestamp); // Immediately update the scrubber position
      onTimestampSelected(selectedTimestamp); // Inform the parent component about the new timestamp
      if (videoRef.current) {
        videoRef.current.setPositionAsync(selectedTimestamp * 1000); // Sync video position
      }
    }
  };

  return (
    <View style={styles.container} onLayout={handleContainerLayout} {...panResponder.panHandlers}>
      <TouchableOpacity activeOpacity={1} onPress={handleContainerTouch} style={styles.touchOverlay}>
        {/* Invisible Touch Overlay */}
      </TouchableOpacity>
      <Scrubber
        value={scrubberValue}
        totalDuration={videoDuration}
        onSlidingComplete={(value: number) => {
          onTimestampSelected(value); // Use the prop callback to inform parent component
          // Directly seek the video to the selected time
          if (videoRef.current) {
            videoRef.current.setPositionAsync(value * 1000); // Sync video position
          }
        }}
        trackColor={'#1AA7EC'}
        scrubbedColor={'#0000FF'}
      />
    </View>
  );
};



export default SelectClipTimestamp;