import { createStackNavigator } from '@react-navigation/stack';
import SubmitClip from '../components/SubmitClip/SubmitClip';
import QuestionForm from '../components/QuestionForm/QuestionForm';
import QuizViewerProfile from '../components/QuizViewerProfile/QuizViewerProfile';
import UserViewOther from '../components/UserViewOther/UserViewOther';
import { StackParamList } from './navigationTypes';
import React from 'react';
import UserView from '../components/UserView/UserView';
import { View } from 'react-native';

const SubmitClipStack = createStackNavigator<StackParamList>();

function SubmitClipNavigator() {
  return (
    <View style={{height: '100%', backgroundColor: 'black'}}>
      <SubmitClipStack.Navigator screenOptions={{headerShown: false}} initialRouteName="SubmitClip">
        <SubmitClipStack.Screen name="SubmitClip" component={SubmitClip} />
        <SubmitClipStack.Screen name="QuestionForm" component={QuestionForm} />
        <SubmitClipStack.Screen name="QuizViewerProfile" component={QuizViewerProfile}/>
        <SubmitClipStack.Screen name="UserViewOther" component={UserViewOther} />
        <SubmitClipStack.Screen name="UserView" component={UserView} />
        {/* Add other screens here if necessary */}
      </SubmitClipStack.Navigator>
    </View>
  );
}

export default SubmitClipNavigator;
