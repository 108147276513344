import React, { FC, useEffect, useState } from 'react';
import { RouteProp, useNavigation } from '@react-navigation/native';
import { useAuth } from '../../context/AuthContext';
import { StackNavigationProp } from '@react-navigation/stack';
import { StackParamList } from '../../navigators/navigationTypes';
import axios from 'axios';
import { ActivityIndicator, View } from 'react-native';

type UserViewProps = {
    route: RouteProp<StackParamList, 'UserView'>;
};

import { API_URL } from '@env';

const UserView: FC<UserViewProps> = ({ route }) => {
    const { userProfile } = useAuth();
    const navigation = useNavigation<StackNavigationProp<StackParamList>>();
    const [loading, setLoading] = useState(true);

    const { username } = route.params;

    const getUserProfile = async () => {
        try {
            const response = await axios.get(`${API_URL}/users/find/${username}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching user profile:', error);
            return null;
        }
    };

    useEffect(() => {
        const navigateToProfile = async () => {
            if (userProfile) {
                if (userProfile.username === username) {
                    navigation.replace('UserViewSelf');
                } else {
                    const userProfileOther = await getUserProfile();
                    if (userProfileOther) {
                        navigation.replace('UserViewOther', { userProfile: userProfileOther });
                    }
                }
            }
            setLoading(false);
        };

        navigateToProfile();
    }, [username, userProfile, navigation]);

    if (loading) {
        return (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" />
            </View>
        );
    }

    return null; // This will be displayed if for some reason loading is false but navigation hasn't happened yet.
};

export default UserView;
