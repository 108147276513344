import { StyleSheet, Dimensions } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%', // Ensure full width
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    padding: 20, // Add some padding for better spacing
  },
  input: {
    width: '80%',
    maxWidth: 500,
    padding: 15,
    marginVertical: 10,
    backgroundColor: 'white',
    borderRadius: 5,
  },
  titleContainer: {
    flexDirection: 'row', // Align items in a row
    alignItems: 'center', // Center items vertically
    padding: 20,
    borderRadius: 20,
    width: '100%',
    maxWidth: 500,
    justifyContent: 'center',
    backgroundColor: '#2069e0'
  },
  title: {
    fontSize: 48,
    color: '#f4d47c',
    fontWeight: 'bold',
    marginBottom: 16,
  },
  gifStyle: {
    width: 60, // Set appropriate width
    height: 60, // Set appropriate height
    marginLeft: 20, // Add some space between the title and the GIF
  },
  slogan: {
    fontSize: 14,
    maxWidth: '90%',
    color: '#bebebe', // Light grey for the slogan
    fontStyle: 'italic',
    marginBottom: 60,
    marginTop: 20
  },
  forgotPasswordText: {
    color: '#f4d47c', // Choose an appropriate color
    fontSize: 16,
    paddingBottom: 10,
    textDecorationLine: 'underline',
    marginVertical: 10, // Adjust as needed
},
  loginButton: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    backgroundColor: '#2069e0', // Blue color for the button
    borderRadius: 30,
    marginBottom: 20,
    marginTop: 20,
  },
  buttonText: {
    fontSize: 18,
    color: '#ffffff', // White color for the button text
    fontWeight: 'bold',
  },
  signUpText: {
    fontSize: 16,
    color: '#878683', // Grey color for the sign-up invitation text
    marginBottom: 20,
  },
  signUpLink: {
    color: '#f4d47c', // Gold color for the sign-up link
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
});

export default styles;
