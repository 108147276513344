// UserSearchNavigator.tsx
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import UserSearch from '../components/UserSearch/UserSearch';
import UserViewOther from '../components/UserViewOther/UserViewOther';
import UserViewSelf from '../components/UserViewSelf/UserViewSelf';
import QuizViewerProfile from '../components/QuizViewerProfile/QuizViewerProfile';
import Quiz from '../components/Quiz/Quiz';
import { StackParamList } from './navigationTypes';
import { View } from 'react-native';
import UserView from '../components/UserView/UserView';

const Stack = createStackNavigator<StackParamList>();

function UserStartNavigator() {
  return (
    <View style={{height: '100%', backgroundColor:'black'}}>
      <Stack.Navigator screenOptions={{headerShown: false}} initialRouteName="UserViewSelf">
        <Stack.Screen name="Quiz" component={Quiz} />
        <Stack.Screen name="UserSearch" component={UserSearch} />
        <Stack.Screen name="UserViewOther" component={UserViewOther} />
        <Stack.Screen name="UserViewSelf" component={UserViewSelf} />
        <Stack.Screen name="UserView" component={UserView} />
        <Stack.Screen name="QuizViewerProfile" component={QuizViewerProfile} />
        {/* ... other screens ... */}
      </Stack.Navigator>
    </View>
  );
}

export default UserStartNavigator;