import { StyleSheet, Dimensions } from "react-native";

const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    alignContent: 'center',
    alignItems: 'center',
    maxHeight: windowHeight,
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },

  // Style for modal background/view
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  submit: {
    marginBottom: 10,
    alignItems: 'center',
    backgroundColor: '#1DA1F2',
    
    alignContent: 'center',
    
    borderRadius: 50
  },
  buttonContainer: {
    marginBottom: 10,
    alignItems: 'center',
    backgroundColor: '#1DA1F2',
    borderRadius: 50,
    // Add any additional styling as needed for the iOS button wrapper
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 25,
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white'

  },
  gifStyle: {
    width: 60, // Set appropriate width
    height: 60, // Set appropriate height
  },

  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent black background
    padding: 0,
  },
  radioButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  radioButton: {
    height: 24,
    width: 24,
    borderRadius: 12,
    borderWidth: 2,
    borderColor: 'white', // Change as needed
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.8)',
  },  
  radioButtonIcon: {
    height: 24,
    width: 24,
    borderRadius: 12,
    backgroundColor: 'white', // Change as needed
  },
  input: {
    borderWidth: 1,
    maxWidth: '80%',
    borderColor: '#ccc',
    padding: 10,
    marginBottom: 25,
    borderRadius: 5,
    color: 'white'
  },
  answerInput: {
    borderWidth: 1,
    maxWidth: '85%',
    width: '85%',
    borderColor: '#ccc',
    color: 'white',
    padding: 10,
    marginBottom: 10,
    borderRadius: 5,
    maxHeight: .075 * windowHeight,
    alignSelf: 'center',
  },
  optionRow: {
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    width: '100%',
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'white'
  },
  tagInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  backButton: {
    position: 'absolute', 
    zIndex: 5,
    top: '5%',
    left: '5%'
  },
  tagInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    borderRadius: 5,
    width: '100%',
    maxWidth: 500,
    marginRight: 10,
    color: 'white'
  },
  tagsScrollView: {
    maxHeight: '20%', // Set a maximum height for the ScrollView
    marginBottom: 10,
  },
  tagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  removeOption: {
    fontSize: 24,
    color: '#ff0000',
    marginLeft: 10,
  },
  tag: {
    flexDirection: 'row',
    backgroundColor: '#1DA1F2',
    padding: 5,
    borderRadius: 5,
    marginRight: 5,
    marginBottom: 5,
  },
  removeTag: {
    marginLeft: 5,
    color: '#ff0000',
  },
});

export default styles;
