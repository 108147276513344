// Admin.tsx
import React, { useState } from 'react';
import { Modal, View, TextInput, Button, Text, StyleSheet, FlatList, TouchableOpacity, Alert } from 'react-native';
import axios from 'axios';
import Image from '../UtilityComponents/UniversalImage';
import { useAuth } from '../../context/AuthContext';
import CustomAlert from '../Hooks/Alert/Alert';
import styles from './style';
import { useSearch } from '../../hooks/user/useSearch';
import { useAdmin } from '../../hooks/user/useAdmin';

type User = {
  _id: string;
  username: string;
  bio: string;
  profilePicture: string | undefined;
};

type AdminProps = {
  visible: boolean;
  onClose: () => void;
};

interface AlertButton {
    text: string;
    onPress: () => void;
    style?: 'default' | 'cancel' | 'destructive'; // Adjust according to your needs
}

interface AlertDetails {
    title: string;
    message: string;
    buttons: AlertButton[]; // Use the AlertButton interface here
    // Add other properties if necessary
}

const Admin: React.FC<AdminProps> = ({ visible, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertDetails, setAlertDetails] = useState<AlertDetails>({
    title: '',
    message: '',
    buttons: []
  });

  const { users, handleSearch } = useSearch();
  const { banUser } = useAdmin();

  const showBanConfirmation = (username: string) => {
    setAlertDetails({
      title: 'Ban User',
      message: `Are you sure you want to ban ${username}?`,
      buttons: [
        {
          text: 'Cancel',
          onPress: () => setAlertVisible(false),
          style: 'cancel',
        },
        {
          text: 'OK',
          onPress: () => {
            banUser(username);
            setAlertVisible(false);
          },
        },
      ],
    });
    setAlertVisible(true);
  };

  const renderItem = ({ item }: { item: User }) => (
    <View>
      <TouchableOpacity onPress={() => setSelectedUser(item.username)}>
        <View style={selectedUser === item.username ? styles.selectedUserItem : styles.userItem}>
          <Image
            source={{ uri: item.profilePicture || 'https://storage.googleapis.com/clipdle-profile-pics/Default_pfp.jpeg' }}
            style={styles.commentAvatar}
            onError={() => console.log('Handle image error')}
          />
          <Text style={styles.userText}>{item.username}</Text>
        </View>
      </TouchableOpacity>
      {selectedUser === item.username && (
        <View style={styles.actionDropdown}>
          <Button title="Ban" onPress={() => showBanConfirmation(item.username)} />
        </View>
      )}
    </View>
  );

  return (
    <>
        <Modal
          animationType="slide"
          transparent={true}
          visible={visible}
          onRequestClose={onClose}
        >
          <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <TextInput
                    style={styles.input}
                    onChangeText={setSearchQuery}
                    value={searchQuery}
                    placeholder="Search users"
                    placeholderTextColor="#999"
                />
                <Button title="Search" onPress={() => handleSearch( searchQuery )} />
                <FlatList
                  data={users}
                  keyExtractor={(item) => item._id}
                  renderItem={renderItem}
                />
                <Button title="Close" onPress={onClose} />
              </View>
          </View>
        </Modal>

        <CustomAlert
            isVisible={alertVisible}
            title={alertDetails.title}
            message={alertDetails.message}
            buttons={alertDetails.buttons}
            onClose={() => setAlertVisible(false)}
        />
    </>
  );
};



export default Admin;