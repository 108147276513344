import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import LoginScreen from '../components/Login/Login';
import SignUpScreen from '../components/SignUp/SignUp';
import QuizViewerProfile from '../components/QuizViewerProfile/QuizViewerProfile';
import { StackParamList } from './navigationTypes';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';

// Define the types for the navigation parameters
export type LoginStackParamList = {
    LoginScreen: undefined;
    SignUpScreen: undefined;
    ForgotPassword: undefined;
    QuizViewerProfile: {
      videoIds: string[]; 
      initialVideoId: string;
      username: string;
  };
};

const LoginStack = createStackNavigator<StackParamList>();

function LoginNavigator() {
    return (
      <LoginStack.Navigator screenOptions={{ headerShown: false }} initialRouteName="LoginScreen">
        <LoginStack.Screen name="LoginScreen" component={LoginScreen} />
        <LoginStack.Screen name="SignUpScreen" component={SignUpScreen} />
        <LoginStack.Screen name="ForgotPassword" component={ForgotPassword} />
        <LoginStack.Screen name="QuizViewerProfile" component={QuizViewerProfile} />
        {/* Add other screens here if necessary */}
      </LoginStack.Navigator>
    );
  }

export default LoginNavigator;
