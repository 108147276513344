import React, { useState } from 'react';
import { View, Text, TextInput, StyleSheet, TouchableOpacity, Alert } from 'react-native';
import Modal from '../Hooks/Modal/Modal'; // Ensure this Modal component supports full customization
import styles from './style';

interface ReportModalProps {
  isVisible: boolean;
  onClose: () => void;
  onReportCopyright: () => void;
  onReportOther: (details?: string) => void;
  username: string | null;
}

const ReportModal: React.FC<ReportModalProps> = ({
  isVisible,
  onClose,
  onReportCopyright,
  onReportOther,
  username,
}) => {
  const [selectedReason, setSelectedReason] = useState<string>('');
  const [additionalDetails, setAdditionalDetails] = useState<string>('');

  const reportOptions = [
    "It's spam",
    "Nudity or sexual content",
    "Hate speech",
    "Violence or dangerous acts",
    "Harassment or bullying",
    "Other",
  ];

  const handleReport = () => {
    if (!selectedReason) {
      Alert.alert('Report', 'Please select a reason for reporting.');
      return;
    }

    if (selectedReason === "Copyright violation") {
      onReportCopyright();
    } else {
      onReportOther(additionalDetails);
    }

    onClose(); // Close the modal after reporting
  };

  return (
    <Modal isOpen={isVisible} title="Report Content" onClose={onClose}>
      <TouchableOpacity style={styles.closeIcon} onPress={onClose}>
        <Text style={{ color: 'black', fontSize: 18 }}>X</Text> {/* Ensure visibility and style */}
      </TouchableOpacity>

      <View style={styles.modalContent}>
        {reportOptions.map((option, index) => (
          <TouchableOpacity
            key={index}
            style={[
              styles.option,
              selectedReason === option && styles.selectedOption,
            ]}
            onPress={() => setSelectedReason(option)}
          >
            <Text style={styles.optionText}>{option}</Text>
          </TouchableOpacity>
        ))}

        {selectedReason === 'Other' && (
          <TextInput
            style={styles.input}
            onChangeText={setAdditionalDetails}
            value={additionalDetails}
            placeholder="Please provide more details"
            multiline
          />
        )}

        <TouchableOpacity style={styles.reportButton} onPress={handleReport}>
          <Text style={styles.reportButtonText}>Report</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};



export default ReportModal;