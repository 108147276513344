// QuizOverlay.tsx
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Animated, Alert, Dimensions } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faThumbsUp, faThumbsDown, faCommentDots, faShareSquare, faTrash, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import Comments from '../Comments/Comments';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { useFocusEffect } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { StackParamList } from '../../navigators/navigationTypes';
import ReportModal from '../ReportModal/ReportModal'; // Import the ReportModal component
import { faFlag } from '@fortawesome/free-solid-svg-icons'; // Import the flag icon for reporting
import { upload } from '@google-cloud/storage/build/cjs/src/resumable-upload';
import Image from '../UtilityComponents/UniversalImage';
import { Share } from 'react-native';
import CustomAlert from '../Hooks/Alert/Alert';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useRefetch } from '../../context/RefetchContext';
import AnswersModal from '../AnswersModal/AnswersModal';
import styles from './style';

import { useCustomAlert } from '../../hooks/useCustomAlert';

type QuizOverlayProps = {
    onAnswerSelect: (answer: string) => void;
    correctAnswer: number;
    question: string | null;
    answers: string[];
    videoId: string | null;
    GCSId: string | null;
    isBlocked: boolean;
    uploaderUsername: string | null;
    isFromProfile: boolean;
};

interface Button {
    text: string;
    onPress: () => void | Promise<void>;
    style?: 'cancel' | 'default' | 'destructive';
}

interface AlertProps {
    isVisible: boolean;
    type: string;
    title: string;
    message: string;
    buttons: Button[];
    onClose: () => void;
}

import { API_URL } from '@env';

const QuizOverlay: React.FC<QuizOverlayProps> = ({ videoId, GCSId, onAnswerSelect, correctAnswer, question, isBlocked, answers, uploaderUsername, isFromProfile }) => {
    const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
    const [showOptions, setShowOptions] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const {userProfile, getToken} = useAuth();
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const [isFollowingUploader, setIsFollowingUploader] = useState(false);
    const [reportModalVisible, setReportModalVisible] = useState(false);
    const [isReported, setIsReported] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const { showCustomAlert, hideCustomAlert, alertProps, setAlertProps } = useCustomAlert();

    const { triggerRefetch } = useRefetch();
  
    const showAlert = (type: string, title: string, message: string) => {
        let buttons;

        switch (type) {
            case 'copyURL':
                buttons = [{ text: "OK", onPress: () => hideCustomAlert() }];
                break;
            case 'deleteVideo':
                buttons = [
                    { text: "Cancel", onPress: () => hideCustomAlert() },
                    { text: "OK", onPress: () => deleteVideo() }
                ];
                break;
            default:
                buttons = [{ text: "OK", onPress: () => hideCustomAlert() }];
        }

        showCustomAlert(title, message, buttons);
    };

    const profilePicUrl = `https://storage.googleapis.com/clipdle-profile-pics/${uploaderUsername}_thumbnail.jpeg`;

    const handleOptionButtonClick = () => {
        setModalVisible(true);
    };

    const handleAnswerClick = (answer: string) => {
        setSelectedAnswer(answer);
        onAnswerSelect(answer);
    }

    const toggleCommentsModal = () => {
        setShowComments(!showComments);
    };

    const thumbsUpScale = useRef(new Animated.Value(1)).current;
    const thumbsDownScale = useRef(new Animated.Value(1)).current;

    const [thumbsUpColor, setThumbsUpColor] = useState(isLiked ? 'gold' : 'white');
    const [thumbsDownColor, setThumbsDownColor] = useState(isDisliked ? 'red' : 'white');

    const navigation = useNavigation<StackNavigationProp<StackParamList>>();

    const navigateToUserViewOther = async (userId: string | null) => {
        try {
        const response = await axios.get(`${API_URL}/users/find/${userId}`);
        const userProfile = response.data;

        navigation.navigate('UserView', {
            username: userProfile.username,
          });
        
        } catch (error) {
        Alert.alert('Error', 'Unable to load user profile.');
        } 
    };

    const animateThumbsUp = () => {
        Animated.sequence([
            Animated.timing(thumbsUpScale, {
                toValue: 1.2,
                duration: 100,
                useNativeDriver: false
            }),
            Animated.timing(thumbsUpScale, {
                toValue: 1,
                duration: 100,
                useNativeDriver: false
            })
        ]).start(() => {
            // Simply set the color after the animation
            setThumbsUpColor('gold');
            setThumbsDownColor('white');
        });
    };

    useFocusEffect(
        useCallback(() => {
            return () => {
            // This will be called when the screen loses focus
            setShowComments(false);
            };
        }, [])
    );
    const handleLike = async () => {

        console.log("liking");
            
        try {

            animateThumbsUp();

            const idToken = await getToken();

            // Call your backend to like the video
            await axios.patch(`${API_URL}/videos/${videoId}/likes/increase`, 
            {},
            {
                headers: {
                    Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
            });

            // Call your backend to add video to user's likedVideos
            await axios.put(`${API_URL}/users/${userProfile?.username}/like/${videoId}`, 
            {},
            {
                headers: {
                    Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
            });

            // Increase uploader's monthly likes
            await axios.patch(`${API_URL}/users/${uploaderUsername}/incrementLikes`, 
            {},
            {
                headers: {
                    Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
            
            });
            
            setIsLiked(true);

            // Handle UI update or state management as needed
        } catch (error) {
            console.error('Error liking video:', error);
        }
    };

    const handleUnlike = async () => {

        console.log("unliking");
        
        try {
            const idToken = await getToken();
            // Call your backend to decrease the like count for the video
            const decreaseLikeResponse = await axios.patch(`${API_URL}/videos/${videoId}/likes/decrease`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
            
            });
            
    
            // Call your backend to remove video from user's likedVideos
            await axios.put(`${API_URL}/users/${userProfile?.username}/unlike/${videoId}`, 
            {},
            {
                headers: {
                    Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
            });
            
    
            // Decrease uploader's monthly likes
            await axios.patch(`${API_URL}/users/${uploaderUsername}/decrementLikes`, 
            {},
            {
                headers: {
                    Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
            
            
            });
            
    
            setIsLiked(false);
            setThumbsUpColor('white');
        } catch (error) {
            console.error('Error unliking video:', error);
            // Handle error, e.g., show a notification to the user
        }
    };
    

    const handleReportCopyright = async () => {
        
        try {

            const idToken = await getToken();

            // Call your backend to like the video
            await axios.patch(`${API_URL}/videos/${videoId}/reportCopyright`, 
            {},
            {
                headers: {
                    Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
            
            });

        // Handle UI update or state management as needed
        } catch (error) {
            console.error('Error reporting video:', error);
        }
    };

    const handleReportOther = async () => {
        
        try {

            const idToken = await getToken();
            // Call your backend to like the video
            const response = await axios.patch(`${API_URL}/videos/${videoId}/reportOther`, 
            {},
            {
                headers: {
                    Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
            
            
            });

            // Handle UI update or state management as needed
        } catch (error) {
            console.error('Error reporting video:', error);
        }
    };
        
    const copyToClipboard = () => {
        const URL = `https://clipdle.tv/video/${GCSId}/${GCSId}/${uploaderUsername}`;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(URL).then(() => {
                
                showAlert('copyURL', 'Copied', 'URL has been copied to clipboard');
            }).catch(err => {
                console.error('Could not copy text: ', err);
            });
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = URL;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy');
                
                showAlert('copyURL', 'Copied', 'URL has been copied to clipboard');
            } catch (err) {
                console.error('Unable to copy: ', err);
            }
            document.body.removeChild(textArea);
        }
    };

    const backToPreviousScreen = () => {
        navigation.goBack();
    }

    // Function to actually delete the video after confirmation
    const deleteVideo = async () => {
        if(!videoId) return;

        try {
            const idToken = await getToken();
            await axios.delete(`${API_URL}/videos/${videoId}`, {
                headers: {
                    Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
        });
            navigation.goBack(); // Navigate back or refresh the view as needed
        } catch (error) {
            console.error('Error deleting video:', error);
        }

        triggerRefetch();

        navigation.goBack(); // or any other logic after deletion
    };

    const handleDeleteVideo = () => {
        showAlert('deleteVideo', 'Confirm Delete', 'Are you sure you want to delete this video?');
    };

    useEffect(() => {
        const fetchStatuses = async () => {
          if (userProfile?.username && videoId) {
            try {
              // Fetch like status
              const likeResponse = await axios.get(`${API_URL}/users/${userProfile.username}/likes/${videoId}`);
              setIsLiked(likeResponse.data.isLiked);
              setThumbsUpColor(likeResponse.data.isLiked ? 'gold' : 'white');
      
              // Fetch dislike status
              const dislikeResponse = await axios.get(`${API_URL}/users/${userProfile.username}/dislikes/${videoId}`);
              setIsDisliked(dislikeResponse.data.isDisliked);
              setThumbsDownColor(dislikeResponse.data.isDisliked ? 'red' : 'white');

            } catch (error) {
              console.error('Error fetching like/dislike status:', error);
              // Optionally set default colors in case of error
              setThumbsUpColor('white');
              setThumbsDownColor('white');
            }
          }
        };
      
        fetchStatuses();
      }, [videoId, userProfile?.username]);

    useEffect(() => {
        if (userProfile && uploaderUsername) {
            setIsFollowingUploader(userProfile.followingUserIds.includes(uploaderUsername));
        }
    }, [userProfile, uploaderUsername]);
    
    return ( 
        <>
            {isFromProfile && (
                <View style={styles.headerContainer}>
                    <TouchableOpacity onPress={() => backToPreviousScreen()} style={styles.backButton}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} size={24} color="white"/>
                    </TouchableOpacity>

                    {isFromProfile && uploaderUsername === userProfile?.username && (
                    <TouchableOpacity onPress={handleDeleteVideo} style={styles.deleteButton}>
                        <FontAwesomeIcon icon={faTrash} color="white" size={24} />
                    </TouchableOpacity>
                    )}
                </View>
            )}
            <View style={styles.overlayContainer}>
                <CustomAlert {...alertProps} />
                                
                <View style={styles.profileContainer}>
                <TouchableOpacity onPress={() => navigateToUserViewOther(uploaderUsername)} style={styles.profileTouchable}>
                    <Image
                        source={{ uri: profilePicUrl }}
                        style={styles.profilePic}
                    />
                    <Text style={styles.username}>{uploaderUsername}</Text>
                </TouchableOpacity>
                </View>

                <TouchableOpacity 
                    testID='optionButton'
                    style={styles.questionOverlay} 
                    onPress={handleOptionButtonClick} // Change this to handleOptionButtonClick
                >
                    <Text style={styles.questionText}>{question}</Text>
                </TouchableOpacity>

                <AnswersModal
                    isVisible={modalVisible}
                    onClose={() => setModalVisible(false)}
                    question={question || ''}
                    answers={answers}
                    videoId={videoId}
                    onAnswerSelect={(answer: string) => {
                        handleAnswerClick(answer); // Existing answer handling function
                    }}
                    correctAnswer={correctAnswer} // Pass the correct answer index to the modal
                />

                {showOptions && (
                    <View style={styles.optionsContainer}>
                        {answers.map((option, index) => (
                            <TouchableOpacity 
                                key={option}
                                style={[
                                    styles.optionButton, 
                                    selectedAnswer === option && index === correctAnswer ? styles.correct : 
                                    selectedAnswer === option ? styles.incorrect : {}
                                ]}
                                onPress={() => handleAnswerClick(option)}
                            >
                                <Text style={styles.optionText}>{option}</Text>
                            </TouchableOpacity>
                        ))}
                    </View>
                )}

                {/* Conditionally render the Comments component and pass in the new props */}
                {showComments && <Comments uploaderUsername={uploaderUsername} videoId={videoId} isVisible={showComments} isBlocked={isBlocked} toggleModal={toggleCommentsModal} />}

                <View style={styles.actionButtons}>

                    <TouchableOpacity
                        style={[styles.iconContainer, isLiked ? styles.disabledButton : {}]}
                        onPress={isLiked ? handleUnlike : handleLike}
                        testID='like-button'
                        >
                        <Animated.View style={{ transform: [{ scaleX: thumbsUpScale }, { scaleY: thumbsUpScale }] }}>
                            <FontAwesomeIcon icon={faThumbsUp} color={thumbsUpColor as any} size={36} />
                        </Animated.View>
                    </TouchableOpacity>



                    <TouchableOpacity style={styles.iconContainer} onPress={toggleCommentsModal}>
                        <FontAwesomeIcon icon={faCommentDots} color="white" size={36} />
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.iconContainer} onPress={copyToClipboard}>
                        <FontAwesomeIcon icon={faShareSquare} color="white" size={36} />
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={styles.iconContainer}
                        onPress={() => setReportModalVisible(true)}
                    >
                        <FontAwesomeIcon icon={faFlag} color="white" size={36} />
                    </TouchableOpacity>

                </View>

                <ReportModal
                    isVisible={reportModalVisible}
                    onClose={() => setReportModalVisible(false)}
                    onReportCopyright={handleReportCopyright}
                    onReportOther={handleReportOther}
                    username={uploaderUsername}
                />

            </View>
        </>
    );
};



export default QuizOverlay;