// AuthContext.tsx
import React, { createContext, useState, FC, ReactNode, useEffect } from 'react';
import Auth0 from 'react-native-auth0';
import firebase from '../firebase';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";

type User = {
  username: string;
  bio: string;
  followingUserIds: string[];
  watchedVideos: string[];
  blockedUserIds: string[];
  // Add any other user profile fields you expect
};

type firebaseUser = {
  uid: string;
  email: string;
  displayName: string;
  // Add any other user profile fields you expect
};

type AuthContextType = {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  userProfile: User | null;
  setUserProfile: (profile: User) => void;
  updateUserFollowing: (userIds: string[]) => void;
  updateWatchedVideos: (videoIds: string[]) => void;
  updateBlockedUsers: (userIds: string[]) => void;
  getToken: () => Promise<string | null>; // Add this line
};


const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  userProfile: null, // Add this line
  setUserProfile: () => {}, // Add this line
  updateUserFollowing: () => {},
  updateWatchedVideos: () => {},
  updateBlockedUsers: () => {},
  getToken: async () => null, // Add this line
});

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<User | null>(null); // Add this line
  const [firebaseUser, setFirebaseUser] = useState<firebaseUser | null>(null); // Add this line

  // Function to update followingUserIds in the userProfile
  const updateUserFollowing = (userIds: string[]) => {
    if (userProfile) {
      setUserProfile({ ...userProfile, followingUserIds: userIds });
    }
  };

  // Inside your AuthProvider component
  useEffect(() => {
    const auth = getAuth();
    
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser && firebaseUser.email) {
        // Map Firebase user to your User type
        const appUser: firebaseUser = {
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firebaseUser.displayName || '',
          // Map any other fields you need
        };
  
        setIsAuthenticated(true);
        setFirebaseUser(appUser); // Use the mapped object here
      } else {
        // User is signed out
        setIsAuthenticated(false);
        setUserProfile(null); // Reset user state
      }
    });
  
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const saveToken = async (idToken: string) => {
    // Save the token along with the current timestamp
    const currentTime = new Date().getTime();
    await AsyncStorage.setItem('idToken', JSON.stringify({ idToken, timestamp: currentTime }));
  };
  
  const getToken = () => {
    return new Promise<string | null>((resolve, reject) => {
      const auth = getAuth(); // Get the Firebase Auth instance
      // Setting up a one-time listener to auth state change
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        unsubscribe(); // Unsubscribe after getting the user state
  
        if (!user) {
          
          resolve(null); // Resolve the promise with null if no user is signed in
          return;
        }
  
        // If a user is signed in, proceed to check the stored token
        const tokenDataString = await AsyncStorage.getItem('idToken');
        const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
        const currentTime = new Date().getTime();
  
        if (tokenData) {
          const { idToken, timestamp } = tokenData;
          const ageMinutes = (currentTime - timestamp) / (1000 * 60);
  
          if (ageMinutes < 55) {
            
            resolve(idToken); // Use cached token if it's still fresh
            return;
          }
        }
  
        // If the cached token is not available or is stale, refresh it
        
        const newIdToken = await getIdToken(user, true); // Force token refresh
        await saveToken(newIdToken); // Save the refreshed token
        resolve(newIdToken); // Resolve the promise with the new token
      }, reject); // In case of an error, reject the promise
    });
  };
  

  const updateWatchedVideos = (videoIds: string[]) => {
    setUserProfile((prevProfile) => {
      if (prevProfile) {
        return { ...prevProfile, watchedVideos: videoIds };
      }
      return prevProfile; // In case prevProfile is null, return it as is.
    });
  };

  // ...inside AuthProvider
  const updateBlockedUsers = (userIds: string[]) => {
    if (userProfile) {
      setUserProfile({ ...userProfile, blockedUserIds: userIds }); // Use the correct property name
    }
  };  

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      setIsAuthenticated,
      userProfile, // Add this line
      setUserProfile, // Add this line
      updateUserFollowing,
      updateWatchedVideos,
      updateBlockedUsers,
      getToken,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;
