import { StyleSheet, Dimensions } from "react-native";
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from 'react-native-responsive-screen';

const MAX_FONT_SIZE = 22;
const responsiveFontSize = Math.min(Dimensions.get('window').width * 0.05, MAX_FONT_SIZE);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: 'black',
    height: Dimensions.get('screen').height,
  },
  header: {
    alignItems: 'center',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  timestamp: {
    marginBottom: 20,
    zIndex: 5,
  },
  subHeader: {
    fontSize: 20,
    marginBottom: 10,
  },
  rules: {
    marginBottom: 20,
  },
  chooseVideo: {
    marginBottom: 10,
    alignItems: 'center',
    backgroundColor: '#1DA1F2',
    
    alignContent: 'center',
    
    borderRadius: 50
  },
  video: {
    width: '100%',
    height: '100%',
  },
  videoView: {
    justifyContent: 'center',
    alignContent: 'center',
    maxHeight: '65%',
    width: '100%',
    position: 'relative',
    padding: 20
  },
  videoContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  videoTouchable: {
    width: '100%',
    height: 500, // Set to the height of your video
  },
  playPauseIcon: {
    position: 'absolute',
    alignSelf: 'center',
    top: '50%',
    transform: [{ translateY: -64 }], // Adjust the position as needed
  },
  pauseButton: {
    position: 'absolute',
    left: wp('50%') - 72,
    zIndex: 50,
    opacity: 0.85,
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 50,
    backgroundColor: 'rgba(255,255,255,0.0)',
  },  
  centeredContent: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(29, 161, 242, 0.75)',
    alignItems: 'center',
    padding: 20, // Add some padding to prevent content from touching the edges
  },
  wavingGifContainer: {
    height: Dimensions.get('window').height * 0.3, // 20% of screen height
    width: Dimensions.get('window').width * 0.5, // 40% of screen width, adjust as needed
    justifyContent: 'center',
    alignItems: 'center',
  },
  wavingGif: {
    height: '100%',
    width: '100%',
    resizeMode: 'contain', // Ensure the GIF fits without stretching
  },
  centeredText: {
    color: 'white',
    textAlign: 'center',
    marginTop: 20,
    fontSize: responsiveFontSize, // Dynamic font size, adjust the multiplier as needed
    paddingHorizontal: 10, // Add horizontal padding
  },
  // ... (rest of your styles)
});

export default styles;
