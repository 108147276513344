import React, { createContext, useContext, useState } from 'react';

// Create Context
const RefetchContext = createContext<any>(null);

// Provider Component
export const RefetchProvider = ({ children }: { children: React.ReactNode }) => {
    const [refetchTrigger, setRefetchTrigger] = useState(0);

    // Function to increment refetchTrigger
    const triggerRefetch = () => setRefetchTrigger(prev => prev + 1);

    return (
        <RefetchContext.Provider value={{ refetchTrigger, triggerRefetch }}>
            {children}
        </RefetchContext.Provider>
    );
};

// Custom hook to use the RefetchContext
export const useRefetch = () => useContext(RefetchContext);
