import { StyleSheet, Dimensions } from "react-native";

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'flex-end', // Align children (modalView) at the bottom
    alignItems: 'center',
  },
  modalView: {
    width: '100%', // Use the full width of the screen
    backgroundColor: 'rgba(24, 24, 24, 1)',
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    opacity: 0.98,
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  answerButton: {
    backgroundColor: 'black',
    padding: 10,
    marginVertical: 10,
    width: '100%',
    textAlign: 'center',
    flexDirection: 'row', // to align the text and the icon
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderWidth: 2,
    borderColor: 'transparent', // to keep the button size the same even when not selected
  },
  correct: {
    borderColor: 'green', // or any other styling for correct answer
    backgroundColor: 'green',
  },
  incorrect: {
    borderColor: 'red', // or any other styling for incorrect answer
    backgroundColor: 'red',
  },
  closeButton: {
      position: 'absolute', // Position the close button absolutely
      top: 10, // Distance from the top of the modalView
      right: 10, // Distance from the right of the modalView
      backgroundColor: 'transparent', // Optional: Adjust as needed
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    color: 'white',
  },
  answerText: {
    color: 'white',
  },
});

export default styles;
