import React, { useState } from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './style';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { useVideoInteraction } from '../../hooks/user/useVideoInteraction';

const AnswersModal = ({ isVisible, onClose, question, videoId, answers, onAnswerSelect, correctAnswer }: {
    isVisible: boolean,
    onClose: () => void,
    question: string,
    videoId: string | null,
    answers: string[],
    onAnswerSelect: (answer: string) => void,
    correctAnswer: number
}) => {

  const [answerStatuses, setAnswerStatuses] = useState(Array(answers.length).fill(null));

  const { userProfile, updateWatchedVideos: updateWatchedProfileVideos, getToken } = useAuth();
  const { handleIncreaseScore, handleAnswerVideo } = useVideoInteraction();

  const handlePress = (answer: string, index: number) => {
    // Set the status for the selected answer
    const updatedStatuses = [...answerStatuses];
    updatedStatuses[index] = 'incorrect';
    if (index === correctAnswer) {
      handleIncreaseScore( videoId || '' );
      updatedStatuses[index] = 'correct';
    }
    setAnswerStatuses(updatedStatuses);

    if (index !== correctAnswer) {
      updatedStatuses[correctAnswer] = 'correct';
      setAnswerStatuses([...updatedStatuses]);
    }

    // Add the video to the user's answered videos after processing
    handleAnswerVideo( videoId || '' );
    onAnswerSelect(answer);
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
    >
      <TouchableOpacity
        style={styles.centeredView}
        activeOpacity={1}
        onPressOut={onClose} // This will trigger when clicking outside of the `modalView`
      >
        <View style={styles.modalView} onStartShouldSetResponder={() => true}> {/* This prevents `onPressOut` from firing when the modal is pressed */}

          <TouchableOpacity style={styles.closeButton} onPress={onClose}>
            <FontAwesomeIcon icon={faTimes} color="white" size={24} />
          </TouchableOpacity>

          <Text style={styles.modalText}>{question}</Text>
          {answers.map((answer, index) => (
            <TouchableOpacity
              key={index}
              style={[
                styles.answerButton,
                answerStatuses[index] === 'correct'
                  ? styles.correct
                  : answerStatuses[index] === 'incorrect'
                  ? styles.incorrect
                  : {},
              ]}
              onPress={() => handlePress(answer, index)}
              disabled={answerStatuses.some(status => status)}
            >
              <Text style={styles.answerText}>{answer}</Text>
              {answerStatuses[index] && (
                <FontAwesomeIcon
                  icon={answerStatuses[index] === 'correct' ? faCheck : faTimes}
                  color={answerStatuses[index] === 'correct' ? 'green' : 'red'}
                  size={20}
                />
              )}
            </TouchableOpacity>
          ))}
        </View>
      </TouchableOpacity>
    </Modal>
  );
};



export default AnswersModal;