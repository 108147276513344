import React, { useState, useRef, useEffect, useCallback } from 'react';
import { View, StatusBar, FlatList, Dimensions, Platform, ActivityIndicator, TouchableOpacity, ViewToken, useWindowDimensions, Text } from 'react-native';
import { RouteProp, useIsFocused } from '@react-navigation/native';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { Video, ResizeMode, AVPlaybackStatus } from 'expo-av';
import QuizOverlay from '../QuizOverlay/QuizOverlay';
import { useAuth } from '../../context/AuthContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { faRepeat, faPlay } from '@fortawesome/free-solid-svg-icons';
import { ViewStyle } from 'react-native';
import { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import styles from './style';
import { debounce } from '../../hooks/utilities/useDebounce';
import { useQuery } from '@tanstack/react-query';
import { ProgressData, QuizProps, 
          VideoResponseData, VideoOrEndMessage, 
          VideoData, ExtendedViewToken, 
            RootTabParamList, QuizScreenRouteProp, 
            QuizScreenNavigationProp } from './QuizTypes';

import { API_URL } from '@env';

const Quiz: React.FC<QuizProps> = (props) => {

  // React state variables
  const [videos, setVideos] = useState<VideoOrEndMessage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lastVideoId, setLastVideoId] = useState<string | null>(null);
  const [questionAnswered, setQuestionAnswered] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<VideoData | null>(null);
  const [watchedVideos, setWatchedVideos] = useState<string[]>([]);
  const [showReplayButton, setShowReplayButton] = useState(false);
  const [loadedVideoIndices, setLoadedVideoIndices] = useState<number[]>([]);
  const [allVideosWatched, setAllVideosWatched] = useState(false);
  const [shouldAddEndMessage, setShouldAddEndMessage] = useState(false);
  const [isReportCheckLoading, setIsReportCheckLoading] = useState(true);
  const [blockedList, setBlockedList] = useState<string[]>([]);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const { userProfile, updateWatchedVideos: updateWatchedProfileVideos, getToken } = useAuth();

  const videoRefs = useRef(new Map());

  const insets = useSafeAreaInsets();
  const bottomInset = insets.bottom || 0;

  const WINDOW_HEIGHT =
    Platform.OS === 'android' && Platform.Version >= 29
      ? Dimensions.get('window').height + (StatusBar.currentHeight || 0)
      : Dimensions.get('window').height;

  const tabBarHeight = 55; // Height of your BottomTabNavigator
  const availableHeight = WINDOW_HEIGHT - (StatusBar.currentHeight || 0) - tabBarHeight - bottomInset;

  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  const videoAspectRatio = 9 / 16; // Adjust this based on your video's aspect ratio
  const videoWidth = Math.min(windowWidth, windowHeight * videoAspectRatio);
  const videoHeight = videoWidth / videoAspectRatio;

  // Dynamically create styles that depend on availableHeight
  const dynamicStyles: { videoContainer: ViewStyle; fullScreenVideo: ViewStyle } = {
    videoContainer: {
      width: undefined,  // This is a valid string for width
      height: availableHeight,  // This is a number, so it's valid for height
      justifyContent: 'center',
      alignItems: 'center',
    },
    fullScreenVideo: {
      aspectRatio: videoAspectRatio,
      width: videoWidth,
      height: videoHeight,
      alignSelf: 'center',
    },
  };

  // Function to dynamically determine video style based on aspect ratio
  const getVideoStyle = (aspectRatioString: string) => {
    const [widthStr, heightStr] = aspectRatioString.split(':');
    const width = parseInt(widthStr, 10);
    const height = parseInt(heightStr, 10);
    const aspectRatio = width / height;
  
    const isMobileFormat = height > width;
  
    if (!isMobileFormat) {
      // Fullscreen style for non-mobile format videos
      return {
        width: '100%', // Width as a string is acceptable for style
        height: '100%', // Height as a string is acceptable for style
        alignSelf: 'center' as const, // Ensure alignSelf uses a valid string value
      };
    } else {
      // Maintain original aspect ratio for mobile format videos
      const videoWidth = Math.min(windowWidth, windowHeight * aspectRatio);
      const videoHeight = videoWidth / aspectRatio;
      return {
        aspectRatio: aspectRatio,
        width: videoWidth, // Ensure width is a number
        height: videoHeight, // Ensure height is a number
        alignSelf: 'center' as const, // Ensure alignSelf uses a valid string value
      };
    }
  };
  

  const setVideoRef = (ref: any, videoId: string) => {
    if (ref) {
      videoRefs.current.set(videoId, ref);
    } else {
      videoRefs.current.delete(videoId);
    }
  };

  const checkVideoReportedStatus = async (videoId: string) => {
    setIsReportCheckLoading(true); // Start loading
    try {
      const idToken = await getToken(); // Retrieve the stored ID token
      
      // Replace with your API endpoint to check if a video is reported
      const response = await axios.get(`${API_URL}/videos/${videoId}/isReportedOther`, {
        headers: {
          Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
        },
      });
      
      const isReportedOther = response.data.isReportedOther;
      
    } catch (error) {
      console.error('Error checking video reported status:', error);
    } finally {
      setIsReportCheckLoading(false); // Finish loading
    }
  };

  const handleViewableItemsChanged = useCallback((info: { viewableItems: ExtendedViewToken[], changed: ExtendedViewToken[] }) => {
    const { viewableItems } = info;
  
    if (viewableItems.length > 0) {
      const firstViewableItem = viewableItems[0].item;
      setCurrentVideo(firstViewableItem);
      setShowReplayButton(false);
      setQuestionAnswered(false);

      // Check if the current video is reported
      if (firstViewableItem) {
        checkVideoReportedStatus(firstViewableItem.id);
      }

      
  
      // Use filter to exclude null indices
      const indices = viewableItems
        .map(viewable => viewable.index)
        .filter((index): index is number => index !== null);
  
      setLoadedVideoIndices(indices);
    } else {
      setLoadedVideoIndices([]);
      setCurrentVideo(null);
    }
  }, []);

  // Important: if the video size changes, this must change
  const getItemLayout = (data: ArrayLike<VideoOrEndMessage> | null | undefined, index: number) => {
    if (data && 'isEndMessage' in data[index]) {
      // Adjust the layout for the end message item
      return { length: 100, offset: 100 * index, index }; // Example values
    }
    // Existing logic for video item layout
    return { length: availableHeight, offset: availableHeight * index, index };
  };

  const viewabilityConfig = {
    itemVisiblePercentThreshold: 50,
  };

  const viewabilityConfigCallbackPairs = useRef([
    { viewabilityConfig, onViewableItemsChanged: handleViewableItemsChanged },
  ]);

  // Function to update watched videos locally and sync with backend
  const updateWatchedVideos = async (newVideoId: string) => {
    const updatedWatchedVideos = [...watchedVideos, newVideoId];
    setWatchedVideos(updatedWatchedVideos);
    updateWatchedProfileVideos(updatedWatchedVideos);

    // Store watched videos in local storage
    await AsyncStorage.setItem('watchedVideos', JSON.stringify(updatedWatchedVideos));

    // Periodically sync with the backend
    if (updatedWatchedVideos.length % 2 === 0) {
      // Replace this URL with your backend endpoint
      
      const idToken = await getToken(); // Retrieve the stored ID token
      const url = `${API_URL}/users/${userProfile?.username}/watchVideo/${newVideoId}`;
      await fetch(url, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ watchedVideos: updatedWatchedVideos }),
      });
    }
  };

  // Function to handle replaying the video
  const handleReplay = () => {
    if (currentVideo && currentVideo.id) {
      const player = videoRefs.current.get(currentVideo.id);
      if (player) {
        // Use setPositionAsync to seek to the beginning
        player.setPositionAsync(0).then(() => {
          // Optionally, you can handle any logic after the seek operation here
          player.playAsync(); // Resume playing after seeking
        });
      } else {
        
      }
      setShowReplayButton(false);
    }
  };

  const ReplayButton = () => (
    <TouchableOpacity
      style={styles.replayButtonContainer}
      onPress={handleReplay}
    >
      <FontAwesomeIcon icon={faRepeat} size={50} color="#FFF" />
    </TouchableOpacity>
  );

  // Debounced fetchVideos function
  const debouncedFetchVideos = useCallback(debounce(async () => {
    try {
      const followingUserIds = userProfile?.followingUserIds; // Assuming this is how you store followingUserIds
      const blockedIds = userProfile?.blockedUserIds;
      if(blockedIds) setBlockedList(blockedIds);

      const idToken = await getToken();

      const watchedVideos = userProfile?.watchedVideos; // Assuming this is how you store watchedVideos

      const response = await fetch(`${API_URL}/videos?followingUserIds=${JSON.stringify(followingUserIds)}&watchedVideos=${JSON.stringify(watchedVideos)}&lastId=${lastVideoId}&blockedUserIds=${JSON.stringify(blockedIds)}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      const data: VideoResponseData[] = await response.json();

      if (data.length > 0) {
        setLastVideoId(data[data.length - 1].id);
        setAllVideosWatched(false);
      } else if (data.length === 0 && !allVideosWatched) {
        setShouldAddEndMessage(true);
        setAllVideosWatched(true);
      }

      // Filter out videos that are already present in the state
      const newVideos = data.filter(video => 
        !videos.some(existingVideo => existingVideo.id === video.id)
      );

      setVideos(prevVideos => [...prevVideos, ...newVideos]);
      setIsLoading(false);

    } catch (error) {
      console.error('Failed to fetch or parse videos:', error);
    }
  }, 600), [userProfile?.followingUserIds, userProfile?.blockedUserIds, userProfile?.watchedVideos, lastVideoId]);


  // Function to handle the progress of a video
  const onProgress = useCallback((data: ProgressData, videoId: string) => {
    if (data.currentTime >= currentVideo!.pausePoint! && !questionAnswered) {
      
      const videoRef = videoRefs.current.get(videoId);
      if (videoRef) {
        videoRef.pauseAsync(); // Pause the video
      }

      setShowReplayButton(true);
    }
  }, [currentVideo, questionAnswered, videoRefs]);

  // Function to handle the playback status update
  const handleOnPlaybackStatusUpdate = useCallback((videoId: string) => (status: AVPlaybackStatus) => {
    if (!status.isLoaded) {
      // Handle error or interruption
      console.log('Video loading error or interruption', status.error);
      setShowPlayButton(true); // Show play button if there was an error loading the video
    } else {
      // Update progress, handle play/pause, etc.

      // Users won't want to watch the same video twice. 
      updateWatchedVideos(videoId); // Update watched videos when the video starts playing

      if (status.isPlaying) {
        const currentTime = status.positionMillis / 1000;
        onProgress({ currentTime }, videoId);
      }
  
      // Optionally, hide play button when video starts playing successfully
      if (status.didJustFinish && !status.isLooping) {
        // Video finished playing
      } else if (status.isPlaying) {
        setShowPlayButton(false); // Hide play button when video starts playing
      }
    }
  }, [onProgress])

  const handlePlay = () => {
    if (videoRefs.current && currentVideo && currentVideo.id) {
      const player = videoRefs.current.get(currentVideo.id);
      player.playAsync().then(() => {
        setShowPlayButton(false); // Assuming playback starts successfully, hide the play button
      }).catch((error: any) => {
        console.log('Error attempting to play the video:', error);
        // Handle error, maybe show some feedback to the user
      });
    }
  };

  const PlayButton = () => (
    <TouchableOpacity
      style={styles.playButtonContainer}
      onPress={handlePlay}
    >
      <FontAwesomeIcon icon={faPlay} size={50} color="#FFF" />
    </TouchableOpacity>
  );

  // Function to handle answer selection
  const handleAnswerSelect = (answer: string, item: any) => {
    const selectedAnswerIndex = item.answers.indexOf(answer);
    if (selectedAnswerIndex === item.correctAnswer) {
      
    } else {
      
    }
    setQuestionAnswered(true); // The question is answered
    setShowReplayButton(false);

    // Resume playing the video
    const videoRef = videoRefs.current.get(item.id);
    if (videoRef) {
      videoRef.playAsync();
    }
  };

  const renderItem = ({ item, index }: { item: VideoOrEndMessage, index: number }) => {
    if ('isEndMessage' in item) {
      // Render end message
      return (
        <View style={styles.endMessageContainer}>
          <Text style={styles.endMessageText}>That's it! Invite your friends for more content!</Text>
        </View>
      );
    } else {

      const videoStyle = getVideoStyle(item.aspectRatio);

      return (
        <View style={dynamicStyles.videoContainer}>
          {isReportCheckLoading && (
            <View style={styles.loadingContainer}>
              <ActivityIndicator testID='loading-indicator' size="large" color="#00ff00" />
            </View>
          )}
          {!isReportCheckLoading && loadedVideoIndices.includes(index) && (
            <Video
              source={{ uri: item.source }}
              style={videoStyle as ViewStyle}
              videoStyle={videoStyle as ViewStyle}
              isLooping
              resizeMode={ResizeMode.COVER as ResizeMode}
              shouldPlay={true}
              ref={(ref) => setVideoRef(ref, item.id)}
              onPlaybackStatusUpdate={handleOnPlaybackStatusUpdate(item.id)}
            />
          )}

          {isLoading && (
            <View style={styles.loadingContainer}>
              <ActivityIndicator size="large" color="#00ff00" />
            </View>
          )}

          {showReplayButton && <ReplayButton />}
          {showPlayButton && <PlayButton />}

          <QuizOverlay 
            videoId={item._id}
            question={item.question as string}
            GCSId={item.id}
            answers={item.answers || []}
            isBlocked={!!item.uploaderUsername && blockedList.includes(item.uploaderUsername)}
            onAnswerSelect={(answer) => handleAnswerSelect(answer, item)}
            correctAnswer={item.correctAnswer}
            uploaderUsername={item.uploaderUsername}
            isFromProfile={false}
          />
        </View>
      );
    }
  }

  // Shared value for opacity
  const opacity = useSharedValue(0);

  // Call this function when there are no more videos to watch
  const showEndMessage = () => {
    opacity.value = withTiming(1, { duration: 2000 });
  };

  const keyExtractor = (item: VideoOrEndMessage) => item.id;

  useEffect(() => {
    if (videos.length === 0) {
      showEndMessage();
    }
  }, [videos]);

  useEffect(() => {
      // Call the fetch function
      debouncedFetchVideos();
  }, [userProfile?.watchedVideos]);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (shouldAddEndMessage && videos.length > 0) {
      setVideos(prevVideos => [...prevVideos, { id: 'endMessage', isEndMessage: true }]);
      setShouldAddEndMessage(false);
    }
  }, [shouldAddEndMessage, videos]);

  return (
    <View style={styles.quizContainer}>
      <FlatList
        data={videos}
        windowSize={4}
        initialNumToRender={0}
        maxToRenderPerBatch={2}
        removeClippedSubviews={true}
        testID='videoList'
        onEndReached={debouncedFetchVideos}
        onEndReachedThreshold={0}
        pagingEnabled={true}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        showsVerticalScrollIndicator={false}
        viewabilityConfig={viewabilityConfig}
        viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
        decelerationRate={'normal'}
        getItemLayout={getItemLayout} // Add this prop
        
      />
    </View>
  );
};



export default Quiz;