import React, { useState } from 'react';

import axios from "axios";
import { useAuth } from '../../context/AuthContext';

import { API_URL } from '@env';

export const useVideoInteraction = () => {

    const { userProfile, updateWatchedVideos: updateWatchedProfileVideos, getToken } = useAuth();

    const handleIncreaseScore = async (videoId: string) => {
      // Fetch the user's ID token
      const idToken = await getToken();
    
      // Increase the score
      if(userProfile && userProfile.username){
        const response = await axios.patch(`${API_URL}/users/${userProfile.username}/${videoId}/increaseScore`, 
        {},
        {
          headers: {
            Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
          },
        });
      }
    };
    
    const handleAnswerVideo = async (videoId: string) => {
      // Fetch the user's ID token
      const idToken = await getToken();
    
      if(userProfile && userProfile.username){
    
        // Add video to the user's answered videos
        const response = await axios.patch(`${API_URL}/users/${userProfile.username}/answerVideo/${videoId}`, 
        {},
        {
          
          headers: {
            Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
          },
        });
      }
    };

    return {
        // Login state and functions
        handleIncreaseScore, 
        handleAnswerVideo
      };

};