import { StyleSheet, Dimensions } from "react-native";

const styles = StyleSheet.create({
    overlayContainer: {
        position: 'absolute',
        bottom: 60,
        left: 10,
        right: 10,
        alignItems: 'center',
        marginLeft: '5%',
        marginRight: '5%',
        justifyContent: 'flex-start',
        flex: 1,
        padding: 10,
        paddingTop: 60, // Make sure this padding is enough to push content below the header
    },

    headerContainer: {
        position: 'absolute',
        top: 0,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        zIndex: 50,
        backgroundColor: 'transparent', // Optional: if you want to style the header's background
      },

    backButton: {
        marginLeft: '5%',
        padding: 8, // Added padding for larger clickable area
        borderRadius: 30, // Circular background
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background for visibility
    },

    deleteButton: { // New style for the delete button
        marginRight: '5%',
        padding: 8, // Consistent with backButton
        borderRadius: 30, // Circular background
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background for visibility
    },

    followingStatus: {
        position: 'absolute',
        top: '-650%',
        right: 10,
        color: 'white',
        fontSize: 14,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: 5,
        borderRadius: 5,
        // Additional styling if needed
    },
    
    profileContainer: {
        flexDirection: 'row', // Aligns children in a row
        alignItems: 'center', // Centers children vertically in the container
        alignSelf: 'flex-start', // Aligns container to the left
        marginVertical: 10, // Adds vertical space for better separation
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderRadius: 20,
        padding: 5,
      },
      profileTouchable: {
        flexDirection: 'row',
        alignItems: 'center',
        // Other styles you want to apply to this container
    },
    profilePic: {
        width: 32,
        height: 32,
        borderRadius: 16, // Make this half of the width/height to make it circular
        marginRight: 10, // Adjust the right margin for spacing between image and text
    },
    username: {
        color: 'white',
        fontWeight: 'bold',
        // Other text styling as needed
    },
    
    questionOverlay: {
        padding: 10,
        maxWidth: '50%',
        backgroundColor: 'rgba(0,0,0,0.6)',
        borderRadius: 5,
    },
    questionText: {
        color: 'white',
        fontSize: 16,
        textAlign: 'center',
    },
    optionsContainer: {
        backgroundColor: 'rgba(0,0,0,0.6)',
        maxWidth: '50%',
    },
    optionButton: {
        padding: 15,
        backgroundColor: 'rgba(0,0,0,0.6)',
        borderRadius: 5,
        maxWidth: '100%',
        alignContent: 'center',
        textAlign: 'center',
    },
    optionText: {
        color: 'white',
        fontSize: 16,
    },
    disabledButton: {
        opacity: 1,
    },      
    correct: {
        backgroundColor: 'green',
    },
    incorrect: {
        backgroundColor: 'red',
    },
    actionButtons: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: 10,
        borderRadius: 25,
        bottom: 0,
        right: 0,
    },
    iconContainer: {
        marginBottom: 20,
        marginTop: 20,
        opacity: 1,
      }
});

export default styles;
