import { StyleSheet, Dimensions } from "react-native";

const styles = StyleSheet.create({
  modalContent: {
    alignItems: 'center',
    justifyContent: 'center', // Center content vertically
    width: '100%', // Ensure full width for alignment
  },
  closeIcon: {
    alignSelf: 'flex-end', // Align self to the end of the container
    marginRight: 20, // Margin from the right edge
    marginTop: 10, // Margin from the top
  },
  option: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    width: '100%',
  },
  selectedOption: {
    backgroundColor: '#e7e7e7',
  },
  optionText: {
    textAlign: 'center',
    fontSize: 16,
  },
  input: {
    width: '100%',
    borderColor: '#ccc',
    borderWidth: 1,
    marginTop: 10,
    padding: 10,
    borderRadius: 5,
    minHeight: 100, // Allow space for multiple lines of text
  },
  reportButton: {
    marginTop: 10,
    backgroundColor: '#2196F3',
    borderRadius: 20,
    padding: 10,
    width: '100%',
  },
  reportButtonText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  // Other styles if needed
});

export default styles;
