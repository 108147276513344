import React, { createContext, useState, useContext } from 'react';

type VideoContextType = {
    videoURI: string | null;
    setVideoURI: (uri: string | null) => void;
};

const VideoContext = createContext<VideoContextType | undefined>(undefined);

export const useVideoURI = () => {
    const context = useContext(VideoContext);
    if (!context) {
        throw new Error('useVideoURI must be used within a VideoProvider');
    }
    return context;
};

export const VideoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [videoURI, setVideoURI] = useState<string | null>(null);

    return (
        <VideoContext.Provider value={{ videoURI, setVideoURI }}>
            {children}
        </VideoContext.Provider>
    );
};
