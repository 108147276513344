// linkingConfig.ts
import * as Linking from 'expo-linking';
import { LinkingOptions, PathConfigMap } from '@react-navigation/native';

const prefix = Linking.createURL('/');

type RootStackParamList = {
  MainTab: undefined;
  LoginNavigator: undefined;
  // ... other root stack screens
};

type MainTabParamList = {
  UserViewSelfStart: undefined;
  UserSearchStart: undefined;
  QuizStart: undefined;
  SubmitClipStart: undefined;
  QuizViewerProfile: {
    videoIds: string[];
    initialVideoId: string;
    username: string;
  };
  // ... other screens in MainTabNavigator
};

const MainTabScreenConfig: PathConfigMap<MainTabParamList> = {
  UserViewSelfStart: 'userview',
  UserSearchStart: 'usersearch',
  QuizStart: 'quiz',
  SubmitClipStart: 'submit',
  QuizViewerProfile: {
    path: 'video/:videoIds/:initialVideoId/:username',
    parse: {
      videoIds: (videoIds: string) => [videoIds],
      initialVideoId: (videoId: string) => videoId,
      username: (username: string) => username,
    },
  },
  // ... other screens in MainTabNavigator
};

export const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [prefix, 'clipdle://', 'https://clipdle.tv', 'http://localhost:19006'],
  config: {
    screens: {
      MainTab: {
        screens: MainTabScreenConfig,
      },
      LoginNavigator: 'login',
      // ... other root stack screens
    },
  },
};
