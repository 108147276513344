import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';

import axios from "axios";
import { Alert } from 'react-native';

type User = {
    _id: string;
    username: string;
    bio: string;
    profilePicture: string | undefined;
};

import { API_URL } from '@env';

export const useAdmin = () => {

    const { userProfile, getToken } = useAuth();

    const banUser = async (username: string) => {
        console.log("Banning user:", username)
        try {
          // Assuming you have the axios instance configured with admin authentication headers
            const idToken = await getToken(); // Retrieve the stored ID token
    
            await axios.put(
                `${API_URL}/admin/ban/${username}`, 
                {},
                {
                    headers: {
                    'Authorization': `Bearer ${idToken}`,
                    },
                }
            );
            Alert.alert("Success", `User ${username} has been banned.`);
        } catch (error) {
            console.error('Error banning user:', error);
            Alert.alert("Error", "Failed to ban user.");
        }
      };

    return {
        // Login state and functions
        banUser,
    };


};