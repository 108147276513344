import React from 'react';
import { Image as RNImage, ImageSourcePropType, ImageProps as RNImageProps, Platform } from 'react-native';
import FastImage, { Source, FastImageProps } from 'react-native-fast-image';

interface UniversalImageProps extends Omit<RNImageProps, 'source'> {
  source: ImageSourcePropType | Source;
}

const Image: React.FC<UniversalImageProps> = ({ source, ...props }) => {
  if (Platform.OS === 'web') {
    // Cast source to ImageSourcePropType for React Native's Image
    return <RNImage source={source as ImageSourcePropType} {...props} />;
  } else {
    // Cast source to Source type for FastImage
    return <FastImage source={source as Source} {...props as FastImageProps} />;
  }
};

export default Image;
