import { StyleSheet, Dimensions } from "react-native";

const styles = StyleSheet.create({
  quizContainer: {
    flex: 1,
    height: '100%',
    backgroundColor: 'black',
  },
  videoContainer: {
    width: '100%',
  },
  fullScreenVideo: {
    position: 'absolute',
    width: '100%',
  },
  replayButtonContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: -45 }, { translateY: -45 }], // Adjust based on the size of your icon
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    padding: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  },
  playButtonContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: -45 }, { translateY: -45 }],
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    padding: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },  
  endMessageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    // Adjust the height if needed, e.g., make it equal to the height of a video item
  },
  endMessageText: {
    color: '#FFF',
    textAlign: 'center',
    // Other styling as needed
  },
  blurOverlay: {
    ...StyleSheet.absoluteFillObject, // This will cover the entire video
    backgroundColor: 'rgba(0, 0, 0, 0.95)', // Adjust opacity for desired blur effect
    justifyContent: 'center',
    zIndex: 502,
    alignItems: 'center',
  },
  reportedWarning: {
    color: 'red',
    fontSize: 18,
    textAlign: 'center',
    padding: 20,
  },
  watchAnywayButton: {
    backgroundColor: 'blue', // Button color
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 5,
  },
  watchAnywayText: {
    color: '#FFFFFF', // Text color
    fontSize: 16,
  },
});

export default styles;
