import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, AlertButton } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import CustomAlert from '../Hooks/Alert/Alert';

interface UserActionModalProps {
  isVisible: boolean;
  onClose: () => void;
  isBlocked: boolean;
  onBlock: () => void;
  onUnblock: () => void;
  onReport: () => void;
}

const UserActionModal: React.FC<UserActionModalProps> = ({
  isVisible,
  onClose,
  isBlocked,
  onBlock,
  onUnblock,
  onReport,
}) => {
  // Prepare the buttons for the CustomAlert
  const buttons: AlertButton[] = [
    {
      text: isBlocked ? 'Unblock User' : 'Block User',
      onPress: isBlocked ? onUnblock : onBlock,
      style: 'default',
    },
    {
      text: 'Report User',
      onPress: onReport,
      style: 'default',
    },
    {
      text: 'Cancel',
      onPress: onClose,
      style: 'cancel',
    },
  ];


  return (
    <CustomAlert
      isVisible={isVisible}
      title="User Actions"
      message="Select an action to perform on this user."
      buttons={buttons}
      onClose={onClose}
    />
  );
};

export default UserActionModal;
