import React, { useState, useEffect } from 'react';
import {
  Modal,
  View,
  Text,
  Button,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Alert,
  KeyboardAvoidingView,
  Platform, 
  StatusBar
} from 'react-native';
import { useAuth } from '../../context/AuthContext';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { StackParamList } from '../../navigators/navigationTypes';
import axios from 'axios';
import { GestureHandlerRootView, PanGestureHandler } from 'react-native-gesture-handler';
import { useSharedValue, useAnimatedStyle, useAnimatedGestureHandler, withSpring, runOnJS } from 'react-native-reanimated';
import Animated from 'react-native-reanimated';
import Image from '../UtilityComponents/UniversalImage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styles from './style';

interface CommentsProps {
  uploaderUsername: string | null;
  videoId: string | null; // Add videoId prop
  isVisible: boolean;
  isBlocked: boolean;
  toggleModal: () => void;
}

interface Comment {
  userId: string;
  text: string;
  _id: string;
  createdAt: string;
  profilePicture: string; // Add this line
}

type NavigateToUserViewOtherFunction = (userId: string) => void;

interface CommentItemProps {
  comment: Comment;
  navigateToUserViewOther: NavigateToUserViewOtherFunction;
}

import { API_URL } from '@env';

const CommentItem: React.FC<CommentItemProps> = ({ comment, navigateToUserViewOther }) => {
  const [imageUri, setImageUri] = useState<string>(`https://storage.googleapis.com/clipdle-profile-pics/${comment.userId}_thumbnail.jpeg`);

  return (
    <View style={styles.commentContainer}>
      <TouchableOpacity onPress={() => navigateToUserViewOther(comment.userId)}>
        <Image
          source={{ uri: imageUri }}
          style={styles.commentAvatar}
          onError={() => setImageUri('https://storage.googleapis.com/clipdle-profile-pics/Default_pfp.jpeg')}
        />
      </TouchableOpacity>
      <View key={comment._id} style={styles.commentContainer}>
        <View>
          <Text style={styles.userIdText}>{comment.userId}</Text>
          <Text style={styles.commentText}>{comment.text}</Text>
        </View>
      </View>
    </View>
  );
};

const Comments: React.FC<CommentsProps> = ({ uploaderUsername, videoId, isVisible, isBlocked, toggleModal }) => {

  const [commentText, setCommentText] = useState('');
  const [comments, setComments] = useState<Comment[]>([]);
  const { userProfile, getToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isSelfBlocked, setIsSelfBlocked] = useState(false);

  const navigation = useNavigation<StackNavigationProp<StackParamList>>();

  // Fetch comments when the modal becomes visible
  useEffect(() => {
    if (isVisible) {
      checkIsBlocked();
      fetchComments();
    }
  }, [isVisible]);

  const navigateToUserViewOther = async (userId: string) => {
    
    try {
      setLoading(true); // If you have a loading state
      const response = await axios.get(`${API_URL}/users/find/${userId}`);
      const userProfile = response.data;

      navigation.navigate('UserView', {
        username: userProfile.username,
      });
      
    } catch (error) {
      Alert.alert('Error', 'Unable to load user profile.');
    } finally {
      setLoading(false); // If you have a loading state
    }
  };

  // Function to fetch comments from backend
  const fetchComments = async () => {
    try {
      const response = await fetch(`${API_URL}/videos/${videoId}/comments`);
      const data = await response.json();
      setComments(data);
    } catch (error) {
      console.error('Failed to fetch comments:', error);
    }
  };

  const checkIsBlocked = async () => {
    try {
      

      const idToken = await getToken(); // Retrieve the stored ID token

      const response = await axios.get(`${API_URL}/users/${uploaderUsername}/blocked`, 
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        }
      
      })

      if(response.data && response.data.length > 0 && response.data.blocked.includes(userProfile?.username)){
        setIsSelfBlocked(true);
      } else {
        
      }
    } catch (error: any) {
      
    }
  }

  // Function to post a new comment to backend
  const postComment = async () => {
    if(isSelfBlocked){
      return;
    }
    
    try {
      const idToken = await getToken(); // Retrieve the stored ID token

      const response = await fetch(`${API_URL}/videos/${videoId}/comments`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userProfile?.username, // Replace with actual user ID
          text: commentText,
        }),
      });
      const data = await response.json();
      setComments([...comments, data]);
    } catch (error) {
      console.error('Failed to post comment:', error);
    }
  };
  const handleCommentSubmit = () => {
    if (commentText.trim()) {
      if (commentText.length > 2000) {
        // Alert the user that the comment is too long
        Alert.alert(
          "Comment Too Long",
          "Comments cannot exceed 2000 characters.",
          [{ text: "OK" }]
        );
      } else {
        postComment();
        setCommentText('');
      }
    }
  };

  // Shared value for translation Y
  const translateY = useSharedValue(0);

  // Gesture handler
  const gestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx: any) => {
      ctx.startY = translateY.value;
    },
    onActive: (event, ctx: any) => {
      translateY.value = ctx.startY + event.translationY;
    },
    onEnd: (_) => {
      if (translateY.value > 100) {
        runOnJS(toggleModal)(); // Wrap toggleModal with runOnJS
      } else {
        translateY.value = withSpring(0);
      }
    },
  });
  

  // Animated style
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value }],
    };
  }, []); // Add dependencies inside the array if any

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
    <View style={styles.centeredView}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={isVisible}
        onRequestClose={toggleModal} // Use the toggleModal prop to hide the modal
      >
        
         <KeyboardAvoidingView
            style={styles.centeredView}
            behavior={Platform.OS === "ios" ? "padding" : "height"}
          >
            
          <PanGestureHandler onGestureEvent={gestureHandler}>
            
            <Animated.View style={[styles.modalView, animatedStyle]}>

                <ScrollView style={{width: '100%'}}>
                {comments.length > 0 ? (
                  comments.map((comment) => (
                    <CommentItem 
                      key={comment._id} 
                      comment={comment} 
                      navigateToUserViewOther={navigateToUserViewOther}
                    />
                  ))              
                ) : (
                  <Text style={styles.modalText}>No comments yet...</Text>
                )}

                </ScrollView>
                <View style={styles.textInputContainer}>
                  <TextInput
                    style={styles.textInput}
                    placeholder="Add a comment..."
                    multiline
                    value={commentText}
                    placeholderTextColor={'white'}
                    onChangeText={setCommentText}
                  />
                  <TouchableOpacity
                    style={styles.buttonSubmit}
                    onPress={handleCommentSubmit}
                  >
                    <Text style={styles.textStyle}>Post</Text>
                  </TouchableOpacity>
                </View>

                <View style={styles.closeButtonContainer}>
                    <TouchableOpacity
                    style={styles.closeButton}
                    onPress={toggleModal}
                    >
                    <Text style={styles.closeButtonText}>X</Text>
                    </TouchableOpacity>
                </View>
              
            </Animated.View>
          </PanGestureHandler>
          </KeyboardAvoidingView>
      </Modal>
    </View>
    </GestureHandlerRootView>
  );
};



export default Comments;