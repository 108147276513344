// App.tsx
import React, { useEffect, useRef, useState } from 'react';
import { NavigationContainer, NavigationContainerRef } from '@react-navigation/native';
import MainTabNavigator from './navigators/MainTabNavigator';
import LoginNavigator from './navigators/LoginNavigator';
import { createStackNavigator } from '@react-navigation/stack';
import { AuthProvider, useAuth } from './context/AuthContext';
import { Provider as PaperProvider } from 'react-native-paper';
import { linking } from './linkingConfig'
import { View, Text, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { VideoProvider } from './context/VideoContext';
import { DeepLinkProvider, useDeepLink } from './context/DeepLinkContext';
import * as Linking from 'expo-linking';
import { LogBox } from 'react-native';
import firebase from './firebase';
import { onIdTokenChanged } from "firebase/auth";
import { RefetchProvider } from './context/RefetchContext';
import Admin from './components/Admin/Admin';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

LogBox.ignoreLogs(['StyleSheet.compose(a, b) is deprecated']);

const RootStack = createStackNavigator();

type User = {
  username: string;
  bio: string;
  followingUserIds: string[];
  watchedVideos: string[];
  blockedUserIds: string[];
  email: string;
  // Add any other user profile fields you expect
};

// Save the original console functions
const originalConsoleWarn = console.warn;
const originalConsoleError = console.error;

// Override console.warn to filter out specific warnings
console.warn = (...args) => {
  const message = args[0] || "";
  // List of patterns to ignore
  const ignorePatterns = [
    "transform style array value is deprecated",
    "shadow* style props are deprecated",
    "textAlignVertical style is deprecated",
    "pointerEvents is deprecated",
    "accessibilityRole is deprecated",
    "accessibilityDisabled is deprecated",
    "focusable is deprecated",
    "TouchableOpacity is deprecated", 
    "Button is deprecated",
    "Cannot record touch end without a touch start",
    "* style array value is deprecated", 
    "Image: style.resizeMode is deprecated", 
    "Animated: `useNativeDriver` is not supported",
    "accessibilityLiveRegion is deprecated",
    "accessibilityComponentType is deprecated",
    "accessibilityTraits is deprecated",
    "accessibilityViewIsModal is deprecated",
    "accessibilityElementsHidden is deprecated",
    "accessibilityIgnoresInvertColors is deprecated",
    "accessibilityHint is deprecated",
    "accessibilityLabel is deprecated",
    "accessibilityLanguage is deprecated",
    "selectable prop is deprecated",
  ];

  // Check if the message matches any ignore patterns
  const shouldIgnore = ignorePatterns.some(pattern => message.includes(pattern));

  if (!shouldIgnore) {
    originalConsoleWarn.apply(console, args);
  }
};

console.error = (...args) => {
  if (/Unexpected text node/.test(args[0])) 
  {
    // Do nothing or handle the case specifically.
  } else if (/TouchableOpacity is deprecated/.test(args[0]))
  {

  } else {
    originalConsoleError.apply(console, args);
  }
};

const App = () => {
  const { isAuthenticated, setIsAuthenticated, setUserProfile } = useAuth();
  const { deepLinkUrl, setDeepLinkUrl } = useDeepLink();
  const navigationRef = useRef<NavigationContainerRef<any>>(null);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminModalVisible, setAdminModalVisible] = useState(false);

  // You may need to use a deep linking library to fetch the initial URL
  useEffect(() => {
    const fetchInitialUrl = async () => {
      const initialUrl = await Linking.getInitialURL();
      
      if (initialUrl) {
        setDeepLinkUrl(initialUrl); // Assuming setDeepLinkUrl is from useDeepLink context
      }
    };

    fetchInitialUrl();
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.title = 'Clipdle';
    }
  }, []);

  const checkToken = async () => {
    setIsLoading(true); // Start loading
    const userProfileString = await AsyncStorage.getItem('userToken');
    if (userProfileString) {
      const userProfile: User = JSON.parse(userProfileString);
      setUserProfile(userProfile);
      setIsAuthenticated(true);
    }
    setIsLoading(false); // Stop loading once token check is complete
  };

  // Example function to determine if the user is an admin
  const checkIfAdmin = async () => {
    // Example check - adjust according to your auth logic
    const userProfileString = await AsyncStorage.getItem('userToken');
    if (userProfileString) {
      const userProfile: User = JSON.parse(userProfileString);
      if (userProfile.email === 'brendan@clipdle.tv') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);   
      }
    }
  };

  useEffect(() => {
    checkIfAdmin();
  }, []);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(firebase.auth, async (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          const expiresIn = new Date(idTokenResult.expirationTime).getTime();
          const tokenDetails = JSON.stringify({
            token: idTokenResult.token,
            expiresIn,
          });
          AsyncStorage.setItem('idTokenDetails', tokenDetails);
        });
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    checkToken();
  }, [deepLinkUrl]);

  useEffect(() => {

    if (isAuthenticated && deepLinkUrl) {

      const urlParts = deepLinkUrl.split('/');
      const videoIds = urlParts[urlParts.length - 3]; // Get videoIds from URL
      const initialVideoId = urlParts[urlParts.length - 2]; // Get initialVideoId from URL
      const username = urlParts[urlParts.length - 1]; // Get username from URL

      // Navigate to QuizViewerProfile with extracted parameters
      navigationRef.current?.navigate('QuizViewerProfile', {
        videoIds: [videoIds],
        initialVideoId: initialVideoId,
        username: username
      });

      // Reset deep link URL to prevent repeated navigation
      setDeepLinkUrl('');
    }
  }, [isAuthenticated, deepLinkUrl, setDeepLinkUrl]);

  useEffect(() => {
    const handleKeyDown = (event: { ctrlKey: any; altKey: any; key: string; }) => {
      if (event.ctrlKey && event.altKey && event.key === 'a') {  
        checkIfAdmin();
        setAdminModalVisible(!adminModalVisible);
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (isLoading) {
    return <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><Text>Loading...</Text></View>;
  }

  const queryClient = new QueryClient();

  return (
    <DeepLinkProvider>
      <RefetchProvider>
        <QueryClientProvider client={queryClient}>
          <View style={{ flex: 1, width: '100%', backgroundColor: 'black' }}>
              <PaperProvider>
                <VideoProvider>
                  <NavigationContainer 
                    linking={linking} 
                    ref={navigationRef}
                    documentTitle={{
                      formatter: (options, route) => 
                        `Clipdle`,
                    }}
                  >
                    <RootStack.Navigator screenOptions={{ headerShown: false }}>
                      {isAuthenticated ? (
                        <RootStack.Screen name="MainTab" component={MainTabNavigator} />
                      ) : (
                        <RootStack.Screen name="LoginNavigatorScreen" component={LoginNavigator} />
                      )}
                    </RootStack.Navigator>
                    {isAdmin && <Admin visible={adminModalVisible} onClose={() => setAdminModalVisible(false)} />}
                  </NavigationContainer>
                </VideoProvider>
              </PaperProvider>
          </View>
        </QueryClientProvider>
      </RefetchProvider>
    </DeepLinkProvider>
  );
};

const AppWrapper = () => {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};

export default AppWrapper;
