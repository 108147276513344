import React, { useState } from 'react';
import { View, TextInput, Button, StyleSheet, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { StackNavigationProp } from '@react-navigation/stack';
import { LoginStackParamList } from '../../navigators/LoginNavigator';
import CustomAlert from '../Hooks/Alert/Alert';
import styles from './style';
import { useAuthentication } from '../../hooks/user/useAuthentication';

const ForgotPassword = () => {
  
    const navigation = useNavigation<StackNavigationProp<LoginStackParamList>>();
    const [email, setEmail] = useState('');
    const { handleForgotPassword, alertVisible, setAlertVisible, alertTitle, alertMessage } = useAuthentication();

    return (
        <View style={styles.container}>
          <CustomAlert
            title={alertTitle}
            message={alertMessage}
            isVisible={alertVisible}
            onClose={() => setAlertVisible(false)} 
            buttons={[{
              text: 'OK',
              onPress: () => setAlertVisible(false)
            }]}      
          />
          <View style={styles.inputContainer}>
              <View style={styles.headerContainer}>
                  <View style={{right: '50%', marginBottom: 10}}>
                  <TouchableOpacity onPress={() => navigation.goBack()}>
                      <FontAwesomeIcon icon={faClose} color='white' size={36}></FontAwesomeIcon>
                  </TouchableOpacity>
                  </View>
                  <Text style={styles.headerText}>Enter your email</Text>
                  <Text style={styles.subHeaderText}>Enter your email and we'll send you instructions on how to reset your password.</Text>
              </View>
              <TextInput
                  style={styles.input}
                  value={email}
                  onChangeText={setEmail}
                  placeholder="Enter your email"
                  placeholderTextColor="white"
              />
          </View>
          <View style={styles.button}>
              <Button color={'blue'} title={"Send Email"} onPress={() => handleForgotPassword( email )} />
          </View>
        </View>
    );
  };

export default ForgotPassword;