import { StyleSheet, Dimensions } from "react-native";

const styles = StyleSheet.create({
    headerText: {
      color: 'white',
      fontSize: 36,
      alignContent: 'center', 
      alignSelf: 'center', 
      textAlign: 'center',
      top: 0,
    },
    subHeaderText: {
      color: 'white',
      fontSize: 14,
      alignContent: 'center', 
      alignSelf: 'center', 
      textAlign: 'center',
      padding: 10
    },
    headerContainer: {
      // Adjust header container styles
      alignItems: 'center',
      marginBottom: 10, // Add some space below the header
    },
    container: {
      flex: 1,
      justifyContent: 'space-between', // Distribute space between elements
      alignItems: 'center',
      backgroundColor: 'black',
      paddingTop: 50, // Add padding at the top for the header
      paddingBottom: 30, // Add padding at the bottom for the button
    },
    inputContainer: {
      width: '80%', // Set the width of the container
      alignItems: 'center', // Center the items
    },
    input: {
      width: '100%', // Make TextInput take full width of its container
      maxWidth: 500,
      backgroundColor: '#1F2833',
      color: 'white',
      paddingHorizontal: 20,
      paddingVertical: 10,
      borderRadius: 10,
      fontSize: 16,
      marginVertical: 10,
      textAlign: 'center'
    },
    button: {
      width: '80%', // Make TextInput take full width of its container
      maxWidth: 500,
      backgroundColor: 'blue',
      color: 'white',
      paddingHorizontal: 20,
      paddingVertical: 10,
      borderRadius: 10,
      fontSize: 16,
      marginVertical: 10,
      textAlign: 'center'
    },
    buttonText: {
      fontSize: 16,
      color: '#ffffff', // White color for the button text
      fontWeight: 'bold',
    },
    title: {
      fontSize: 48,
      color: '#f4d47c', // Gold color for the title
      fontWeight: 'bold',
      marginBottom: 16,
    },
    signUpText: {
      fontSize: 16,
      color: '#bebebe', // Light grey for other text
      textAlign: 'center',
      marginBottom: 20,
    },
    signUpLink: {
      color: '#f4d47c', // Gold color for links
      fontWeight: 'bold',
      textDecorationLine: 'underline',
    },
  });

export default styles;
