import React, { useEffect, useRef, useState, useCallback, useReducer } from 'react';
import { View, Text, TextInput, Button, StyleSheet, TouchableOpacity, Modal, Dimensions, Platform } from 'react-native';
import { useRoute, RouteProp, useIsFocused } from '@react-navigation/native';
import { RadioButton } from 'react-native-paper';
import { useAuth } from '../../context/AuthContext';
import { Alert } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { StackNavigationProp } from '@react-navigation/stack';
import { StackParamList } from '../../navigators/navigationTypes';
import axios from 'axios'; // If you haven't installed axios yet, do so via npm install axios
import { Video, ResizeMode } from 'expo-av';
const clipdleGif = require('../../assets/gifs/clipdle1.gif');
import Image from '../UtilityComponents/UniversalImage';
import { useVideoURI } from '../../context/VideoContext';
import { useRefetch } from '../../context/RefetchContext';
import { globalEvents } from '../../context/GlobalEvents';

import * as Progress from 'react-native-progress';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styles from './style';

// Define the expected params for this screen
type RootStackParamList = {
  QuestionForm: {
    pausePoint: number;
    videoWidth: number;
    videoHeight: number;
    filename: string;
    isVisible: boolean;
    onClose: () => void;
  };
  // Add other screen types here if you have more
};

type FormState = {
  question: string;
  options: string[];
  tags: string[];
  newTag: string;
  answer: number | null;
  isLoading: boolean;
  isVideoPlaying: boolean;
  progress: number;
  isAnswered: boolean;
};

type FormAction =
  | { type: 'SET_FIELD'; field: keyof FormState; value: any }
  | { type: 'ADD_OPTION' }
  | { type: 'REMOVE_OPTION'; index: number }
  | { type: 'ADD_TAG' }
  | { type: 'REMOVE_TAG'; tag: string };

const MAX_CHAR_LIMIT = 140;

// Define initial state
const initialState = {
  question: '',
  options: ['', ''],
  tags: [],
  newTag: '',
  answer: null,
  isLoading: false,
  isVideoPlaying: false,
  progress: 0,
  isAnswered: false,
};

// Define reducer function
function formReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'ADD_OPTION':
      return { ...state, options: [...state.options, ''] };
    case 'REMOVE_OPTION':
      return { ...state, options: state.options.filter((_, idx) => idx !== action.index) };
    case 'ADD_TAG':
      return { ...state, tags: [...state.tags, state.newTag], newTag: '' };
    case 'REMOVE_TAG':
      return { ...state, tags: state.tags.filter(tag => tag !== action.tag) };
    default:
      return state;
  }
}

type Props = {
  isVisible: boolean;
  onClose: () => void;
  pausePoint: number;
  videoWidth: number;
  videoHeight: number;
  filename: string;
  file: File | null;
};

import { API_URL } from '@env';

const QuestionFormModal: React.FC<Props> = ({ isVisible, onClose, pausePoint, videoWidth, videoHeight, filename, file, ...restProps }) => {

  const { userProfile, getToken } = useAuth();
  const { videoURI, setVideoURI } = useVideoURI(); // Use the videoURI from context
  const [state, dispatch] = useReducer(formReducer, initialState);
  const { question, options, tags, newTag, answer, isLoading, isVideoPlaying, progress, isAnswered } = state;
  const route = useRoute<RouteProp<RootStackParamList, 'QuestionForm'>>();
  const navigation = useNavigation<StackNavigationProp<StackParamList>>();
  const videoRef = useRef<Video>(null);
  const { triggerRefetch } = useRefetch();

  // Update handlers to dispatch actions
  const handleQuestionChange = (text: string) => {
    dispatch({ type: 'SET_FIELD', field: 'question', value: text.slice(0, MAX_CHAR_LIMIT) });
  };

  useEffect(() => {
    if (videoRef.current) {
      if(!isVideoPlaying){
        videoRef.current.pauseAsync();
      } else if (isVideoPlaying){
        videoRef.current.playAsync();
      }
    }
  }, [isVideoPlaying, videoRef.current]);

  // Function to add a new option
  const handleAddOption = () => {
    if (options.length < 4) {
      dispatch({ type: 'ADD_OPTION' });
    }
  };

  // Function to remove an option
  const handleRemoveOption = useCallback((index: number) => {
    dispatch({ type: 'REMOVE_OPTION', index });
  }, []);

  const validateInputs = () => {
    // Check for question constraints
    if (question.trim() === '' || question.length > MAX_CHAR_LIMIT) {
      Alert.alert("Error", "Please enter a question within 140 characters.");
      return false;
    }
  
    // Validate options
    let nonEmptyOptionCount = 0;
    for (const option of options) {
      if (option.trim() !== '') {
        nonEmptyOptionCount++;
        if (option.length > MAX_CHAR_LIMIT) {
          Alert.alert("Error", "Each answer must be less than 140 characters.");
          return false;
        }
      }
    }
    if (nonEmptyOptionCount < 2) {
      Alert.alert("Error", "Please provide at least two answer options.");
      return false;
    }
  
    // Validate tags
    for (const tag of tags) {
      if (tag.length > MAX_CHAR_LIMIT) {
        Alert.alert("Error", "Each tag must be less than 140 characters.");
        return false;
      }
    }
  
    if (!isAnswered) {
      Alert.alert("You must select an answer");
      return false;
    }
  
    return true;
  };
  

  const handleAddTag = () => {
    if (newTag.trim() !== '' && !tags.includes(newTag)) {
      dispatch({ type: 'ADD_TAG' });
    }
  };

  // useCallback for handleRemoveTag
  const handleRemoveTag = useCallback((tag: string) => {
    dispatch({ type: 'REMOVE_TAG', tag });
  }, []);

  // Render a button with or without a wrapping View based on the platform
  const renderButton = (title: string, onPress: () => void) => {
    let color: string;
    if(Platform.OS === 'ios'){
      color = "white";
    } else {
      color = "blue";
    }

    const button = (
      <Button title={title} onPress={onPress} color={color} />
    );

    // Wrap the button in a View for iOS
    if (Platform.OS === 'ios') {
      return <View style={styles.buttonContainer}>{button}</View>;
    } else {
      // Directly return the button for Android
      return button;
    }
  };

  const handleChooseAnswer = (index: number) => {
    dispatch({ type: 'SET_FIELD', field: 'answer', value: index });
    dispatch({ type: 'SET_FIELD', field: 'isAnswered', value: true });
  };
 

  const isFocused = useIsFocused();

  useEffect(() => {
    dispatch({ type: 'SET_FIELD', field: 'isVideoPlaying', value: isFocused });
  }, [isFocused]);

  const handleSubmit = async () => {

    if (!validateInputs()) return; // Stops submission if validation fails

    dispatch({ type: 'SET_FIELD', field: 'isLoading', value: true });

    // Configuration for tracking upload progress of the video file
    const videoUploadConfig = {
      onUploadProgress: (progressEvent: any) => { // Use 'any' to bypass the strict type checking
        const percentCompleted = Math.round((progressEvent.loaded * 50) / progressEvent.total);
        dispatch({ type: 'SET_FIELD', field: 'progress', value: percentCompleted / 100 });
        // Update your state or UI component here with percentCompleted
      },
    };

    // Configuration for tracking upload progress of the metadata
    const metadataUploadConfig = {
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = 50 + Math.round((progressEvent.loaded * 50) / progressEvent.total);
        dispatch({ type: 'SET_FIELD', field: 'progress', value: percentCompleted / 100 });
        // Update your state or UI component here with percentCompleted
      },
    };

    // First, create a FormData object for the video file
    const videoFormData = new FormData();
    if (file) videoFormData.append('file', file);

    try {
      // Upload the video file first
      const idToken = await getToken(); // Retrieve the stored ID token
      
      const videoUploadResponse = await axios.post(
        `${API_URL}/upload-video`,
        videoFormData,
        {
          ...videoUploadConfig,
          headers: {
            Authorization: `Bearer ${idToken}`
          },
        }
      );
  
      const { filename, aspectRatio } = videoUploadResponse.data;
  
      // Now, create a new FormData object for the metadata
      const metadataFormData = new FormData();
      metadataFormData.append('question', question);
      metadataFormData.append('filename', filename);
      metadataFormData.append('aspectRatio', aspectRatio);
      if (userProfile) metadataFormData.append('userId', userProfile.username);
      tags.forEach((tag, index) => {
        metadataFormData.append(`tags[${index}]`, tag);
      });
      options
        .filter(option => option.trim() !== "")
        .forEach((option, index) => {
          metadataFormData.append(`answers[${index}]`, option);
        });
      metadataFormData.append('pausePoint', String(pausePoint));
      if (answer !== null) metadataFormData.append('correctAnswer', String(answer));

      // Create a new FormData object for the user data
      const userFormData = new FormData();
      if(userProfile) userFormData.append('username', userProfile.username);
      userFormData.append('videoId', filename);

      // Upload the metadata
      await axios.post(`${API_URL}/upload`, 
        metadataFormData, // Pass FormData directly here
        {
          ...metadataUploadConfig,
          headers: {
            'Authorization': `Bearer ${idToken}`, // Correctly set the Authorization header here
            'Content-Type': 'multipart/form-data' // This line is important for FormData upload
          },
        }
      );

      // Upload video data for association with user profile
      if(userProfile) {
        await axios.put(
          `${API_URL}/users/${userProfile.username}/upload/${filename}`,
          {}, // This is where the request body should go. Use an empty object since there's no body.
          {
            headers: {
              'Authorization': `Bearer ${idToken}`,
            },
          }
        );
      }
  
      // Handle successful upload
      dispatch({ type: 'SET_FIELD', field: 'isLoading', value: false });
      // Navigate to the next page
      if( userProfile ) {
        onClose();

        triggerRefetch(); // Trigger refetch to update the list of videos
        globalEvents.emit('videoUploaded');

        navigation.navigate('QuizViewerProfile', {
          videoIds: [filename], // Assuming this is an array of IDs
          initialVideoId: filename, // The newly uploaded video ID
          username: userProfile.username, // Assuming you have the username in userProfile
        });
      }
    } catch (error) {
      dispatch({ type: 'SET_FIELD', field: 'isLoading', value: false });
      console.error('Error uploading video and metadata:', error);
      // Handle error
    }
  };

  const dynamicStyles = StyleSheet.create({
    videoPlayer: {
      position: 'absolute',
      width: videoWidth,
      height: videoHeight,
      alignSelf: 'center',
    },
  });

  return (

    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
    >

      <View style={{flex: 1, alignContent: 'center', justifyContent: 'space-evenly', width: '100%', backgroundColor: 'black'}}>
        {/* Video Player */}
        {!isLoading && (
          <Video
            ref={videoRef}
            source={{ uri: videoURI || '' }}
            style={dynamicStyles.videoPlayer}
            videoStyle={dynamicStyles.videoPlayer}
            resizeMode={ResizeMode.COVER as ResizeMode}
            shouldPlay={!isVideoPlaying}
            isLooping
          />
        )}
        
      <View style={{height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
        {isLoading && (
          <View style={styles.loadingContainer}>
            <Image 
              source={clipdleGif} // Replace with your GIF's path
              style={styles.gifStyle}
            />
            <Progress.Bar progress={progress} width={300} />
          </View>
        )}

        {!isLoading && (
          <View style={styles.overlay}>
          <View style={styles.container}>
            <Text style={styles.header}>Submit Your Question</Text>
            <TouchableOpacity onPress={onClose} style={styles.backButton}>
              <FontAwesomeIcon icon={faArrowAltCircleLeft} size={24} color="white"/>
            </TouchableOpacity>
            <TextInput 
              placeholder="Enter your question here" 
              placeholderTextColor={'white'}
              style={styles.input} 
              value={question}
              onChangeText={text => handleQuestionChange(text.slice(0, MAX_CHAR_LIMIT))}
              maxLength={MAX_CHAR_LIMIT} // Limits the character input
            />
            
            <View style={{position: 'relative', top: '15%', flex: 2}}>
            {options.map((option, index) => (
              <View key={`option-${index}`} style={styles.optionRow}>
                {Platform.OS === 'ios' ? (
                  // Custom radio button for iOS
                  <TouchableOpacity
                    key={`option-opacity-${index}`}
                    style={styles.radioButtonContainer}
                    onPress={() => handleChooseAnswer(index)}
                  >
                    <View style={[styles.radioButton, answer === index && styles.radioButtonIcon]} />
                  </TouchableOpacity>
                ) : (
                  // RadioButton from react-native-paper for Android
                  <RadioButton
                    value={index.toString()}
                    status={answer === index ? 'checked' : 'unchecked'}
                    onPress={() => handleChooseAnswer(index)}
                  />
                )}

                <TextInput 
                  key={`option-${index}`}
                  placeholder={`Option ${index + 1}`} 
                  placeholderTextColor={'white'}
                  style={styles.answerInput} 
                  value={option}
                  onChangeText={(text) => {
                    const newOptions = [...options];
                    newOptions[index] = text;
                    dispatch({ type: 'SET_FIELD', field: 'options', value: newOptions });
                  }}
                />

                {index >= 2 && (
                  <TouchableOpacity style={{padding: 5}} onPress={() => handleRemoveOption(index)}>
                    <FontAwesomeIcon icon={faMinusCircle} size={24} color="red"/>
                  </TouchableOpacity>
                )}

              </View>
            ))}

            {/* Display plus button if less than 4 options */}
            {options.length < 4 && renderButton("Add Option", handleAddOption)}

          <View style={{position: 'relative', top: '15%', flex: 3}}>

            <Text style={styles.label}>Tags:</Text>

            <View style={styles.tagInputContainer}>
              <TextInput
                placeholder="Add a tag (optional)"
                placeholderTextColor={'white'}
                value={newTag}
                onChangeText={(text) => dispatch({ type: 'SET_FIELD', field: 'newTag', value: text.slice(0, MAX_CHAR_LIMIT) })}
                style={styles.tagInput}
              />
              
              {renderButton("Add", handleAddTag)}
            </View>

            {/* Display tags */}
            <View 
              style={styles.tagsScrollView}
              
            >
              {tags.map((tag, index) => (
                <View key={`tag-${index}`} style={styles.tag}>
                  <Text style={{color: 'white'}}>{tag}</Text>
                  <TouchableOpacity onPress={() => handleRemoveTag(tag)}>
                    <Text style={styles.removeTag}>×</Text>
                  </TouchableOpacity>
                </View>
              ))}
            </View>
            </View>

          </View>
          </View>

          <View>
              {renderButton("Submit", handleSubmit)}
          </View>

        </View>
        )}
        
        </View>
      </View>

    </Modal>

  );
};



export default QuestionFormModal;