import React, { useState, useEffect, useRef, useCallback } from 'react';
import { View, StyleSheet, FlatList, Dimensions, ActivityIndicator, TouchableOpacity, StatusBar, ViewToken, Platform, ViewStyle } from 'react-native';
import { Video, ResizeMode, AVPlaybackStatus } from 'expo-av';
import QuizOverlay from '../QuizOverlay/QuizOverlay';
import { RouteProp, useIsFocused } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { faRepeat, faPlay } from '@fortawesome/free-solid-svg-icons'; // Import the replay icon

import { StackParamList } from '../../navigators/navigationTypes';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { render } from '@testing-library/react-native';
import styles from './style';

type VideoData = {
  _id: string;
  id: string;
  source: any;
  pausePoint?: number;
  question: string | null;
  answers: string[] | null;
  correctAnswer: number;
  uploaderUsername: string | null;
  aspectRatio: string;
};

type ProgressData = {
  currentTime: number;
  // Include other fields from the event if necessary
};

type QuizViewerProfileProps = {
    route: RouteProp<StackParamList, 'QuizViewerProfile'>;
};

interface ExtendedViewToken extends ViewToken {
  item: VideoData;
}

const VID_HEIGHT = Dimensions.get('window').height - (StatusBar.currentHeight || 0);

import { API_URL } from '@env';

const QuizViewerProfile: React.FC<QuizViewerProfileProps> = ({ route }) => {
  const [videos, setVideos] = useState<VideoData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [showReplayButton, setShowReplayButton] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [questionAnswered, setQuestionAnswered] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<VideoData | null>(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number | null>(null);
  const [loadedVideoIndices, setLoadedVideoIndices] = useState<number[]>([]);
  const [currentVideoId, setCurrentVideoId] = useState<string | null>(null);
  const [showPlayButton, setShowPlayButton] = useState(true);


  const { videoIds, initialVideoId, username } = route.params;
  const navigation = useNavigation();

  const flatListRef = useRef<FlatList<VideoData> | null>(null);
  const videoRefs = useRef(new Map());
  const videoRef = useRef<Video>(null);

  const insets = useSafeAreaInsets();
  const bottomInset = insets.bottom || 0;

  const WINDOW_HEIGHT =
    Platform.OS === 'android' && Platform.Version >= 29
      ? Dimensions.get('window').height + (StatusBar.currentHeight || 0)
      : Dimensions.get('window').height;

  const tabBarHeight = 55; // Height of your BottomTabNavigator
  const availableHeight = WINDOW_HEIGHT - (StatusBar.currentHeight || 0) - tabBarHeight - bottomInset;


  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  const videoAspectRatio = 9 / 16; // Adjust this based on your video's aspect ratio
  const videoWidth = Math.min(windowWidth, windowHeight * videoAspectRatio);
  const videoHeight = videoWidth / videoAspectRatio;

  // Dynamically create styles that depend on availableHeight
  const dynamicStyles: { videoContainer: ViewStyle; fullScreenVideo: ViewStyle } = {
    videoContainer: {
      width: undefined,  // This is a valid string for width
      height: availableHeight,  // This is a number, so it's valid for height
      justifyContent: 'center',
      alignItems: 'center',
    },
    fullScreenVideo: {
      aspectRatio: videoAspectRatio,
      width: undefined,
      height: '100%',
      alignSelf: 'center',
    },
  };

  const setVideoRef = (ref: any, videoId: string) => {
    if (ref) {
      videoRefs.current.set(videoId, ref);
    } else {
      videoRefs.current.delete(videoId);
    }
  };

  // Function to fetch specific video data
  const fetchVideoData = async (id: string) => {

    try {
      const response = await fetch(`${API_URL}/videos/${id}`);
      const data: VideoData = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch video:', error);
    }
  };

  // Adjust the onAnswerSelect function
  const handleAnswerSelect = (answer: string, item: any) => {
    const selectedAnswerIndex = item.answers.indexOf(answer);
    if (selectedAnswerIndex === item.correctAnswer) {
      
    } else {
      
    }
    setQuestionAnswered(true); // The question is answered
    setShowReplayButton(false);

    // Resume playing the video
    const player = videoRef.current;
    if (player) {
      player.playAsync();
    }
  };

  useEffect(() => {
    if (currentVideo && currentVideo.id) {
      const player = videoRef.current;
      if (player) {
        if(isPaused) {
          player.pauseAsync();
        } else {
          player.playAsync();
        }
      } 
    }
  }, [isPaused]);

  const backToPreviousScreen = () => {
    navigation.goBack();
  }

  const scrollToIndexFailed = (info: any) => {
    const wait = new Promise(resolve => setTimeout(resolve, 500));
    wait.then(() => {
      flatListRef.current?.scrollToIndex({ index: info.index, animated: true });
    });
  };

  const onProgress = useCallback((data: ProgressData, videoId: string) => {
    setCurrentTime(data.currentTime);

    // Check if currentVideo and pausePoint are defined
    if (currentVideo && currentVideo.pausePoint !== undefined) {

      setShowPlayButton(false);
      if (data.currentTime >= currentVideo.pausePoint && !questionAnswered) {
        const player = videoRef.current;
        if(player) {
          player.pauseAsync();
        }
        setIsPaused(true);
        setShowReplayButton(true);
      }
    }
  }, [currentVideo, questionAnswered, videoRefs]);

  // Function to handle replaying the video
  const handleReplay = () => {
    if (currentVideo && currentVideo.id) {
      const player = videoRef.current;
      if (player) {
        // Use setPositionAsync to seek to the beginning
        player.setPositionAsync(0).then(() => {
          // Optionally, you can handle any logic after the seek operation here
          player.playAsync(); // Resume playing after seeking
        });
      } else {
        
      }
      setShowReplayButton(false);
    }
  };

  const ReplayButton = () => (
    <TouchableOpacity
      style={styles.replayButtonContainer}
      onPress={handleReplay}
    >
      <FontAwesomeIcon icon={faRepeat} size={50} color="#FFF" />
    </TouchableOpacity>
  );

  const PlayButton = () => (
    <TouchableOpacity
      style={styles.playButtonContainer}
      onPress={handlePlay}
    >
      <FontAwesomeIcon icon={faPlay} size={50} color="#FFF" />
    </TouchableOpacity>
  );

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.playAsync();
      setShowPlayButton(false);
    }
  };

  const getVideoStyle = (aspectRatioString: string) => {
    const [widthStr, heightStr] = aspectRatioString.split(':');
    const width = parseInt(widthStr, 10);
    const height = parseInt(heightStr, 10);
    const aspectRatio = width / height;
  
    const isMobileFormat = height > width;
  
    if (!isMobileFormat) {
      // Fullscreen style for non-mobile format videos
      return {
        width: '100%', // Width as a string is acceptable for style
        height: '100%', // Height as a string is acceptable for style
        alignSelf: 'center' as const, // Ensure alignSelf uses a valid string value
      };
    } else {
      // Maintain original aspect ratio for mobile format videos
      const videoWidth = Math.min(windowWidth, windowHeight * aspectRatio);
      const videoHeight = videoWidth / aspectRatio;
      return {
        aspectRatio: aspectRatio,
        width: videoWidth, // Ensure width is a number
        height: videoHeight, // Ensure height is a number
        alignSelf: 'center' as const, // Ensure alignSelf uses a valid string value
      };
    }
  };

  const renderItem = ({ item, index }: { item: VideoData, index: number }) => {

    const videoStyle = getVideoStyle(item.aspectRatio);

      return (
        <View style={dynamicStyles.videoContainer}>

          <Video
            source={{ uri: item.source }}
            style={videoStyle as ViewStyle}
            videoStyle={videoStyle as ViewStyle}
            isLooping
            resizeMode={ResizeMode.COVER as ResizeMode}
            shouldPlay={true}
            onLoad={() => setIsPaused(false)}
            ref={videoRef}
            onPlaybackStatusUpdate={handleOnPlaybackStatusUpdate(item.id)}
          />

          {isLoading && (
            <View style={styles.loadingContainer}>
              <ActivityIndicator size="large" color="#00ff00" />
            </View>
          )}

          {showPlayButton && <PlayButton />}
          {showReplayButton && <ReplayButton />}

          <QuizOverlay 
            videoId={item._id}
            question={item.question as string}
            GCSId={item.id}
            isBlocked={false}
            answers={item.answers || []}
            onAnswerSelect={(answer) => handleAnswerSelect(answer, item)}
            correctAnswer={item.correctAnswer}
            uploaderUsername={username}
            isFromProfile={true}
          />
        </View>
      );
  }

  const handleViewableItemsChanged = useCallback((info: { viewableItems: ExtendedViewToken[], changed: ExtendedViewToken[] }) => {
    const { viewableItems } = info;
  
    if (viewableItems.length > 0) {
      const firstViewableItem = viewableItems[0].item;
      setCurrentVideoId(firstViewableItem.id);
      setCurrentVideo(firstViewableItem);
      setIsPaused(false);
      setShowReplayButton(false);
      setQuestionAnswered(false);
  
      
  
      // Use filter to exclude null indices
      const indices = viewableItems
        .map(viewable => viewable.index)
        .filter((index): index is number => index !== null);
  
      setLoadedVideoIndices(indices);
    } else {
      setLoadedVideoIndices([]);
      setCurrentVideo(null);
    }
  }, []);

  const screenHeight = Dimensions.get('screen').height;

  const getItemLayout = (data: ArrayLike<VideoData> | null | undefined, index: number) => ({
    length: VID_HEIGHT,
    offset: VID_HEIGHT * index,
    index,
  });

  const viewabilityConfig = {
    itemVisiblePercentThreshold: 50,
  };

  const viewabilityConfigCallbackPairs = useRef([
    { viewabilityConfig, onViewableItemsChanged: handleViewableItemsChanged },
  ]);

  // Adjusted handleOnPlaybackStatusUpdate function
  const handleOnPlaybackStatusUpdate = useCallback((videoId: string) => (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      if (status.isPlaying) {
        // Update progress
        const currentTime = status.positionMillis / 1000;
        onProgress({ currentTime }, videoId);
      }
      // Add other status handling if needed
    }
  }, [onProgress]);

  // useEffect to ensure the video plays when the ref is set
  useEffect(() => {
    if (currentVideo && currentVideo.id) {
      if(videoRef){
        const player = videoRef;
        if (player) {
          player.current?.playAsync();
        }
      }
    }
  }, [currentVideo, videoRef]);

  useEffect(() => {
    const loadVideos = async () => {
      setIsLoading(true);
      
      try {
        
        const videoDataPromises = videoIds.map(id => fetchVideoData(id));
        const videoData = await Promise.all(videoDataPromises);
        const filteredVideoData = videoData.filter((v): v is VideoData => v !== undefined);
        setVideos(filteredVideoData);
        

        // Set the initial video if necessary
        if (initialVideoId) {
          
          const initialVideo = filteredVideoData.find(video => video.id === initialVideoId);
          if (initialVideo) {
            
            setCurrentVideo(initialVideo);
            if(videoRefs.current) {
              
              const player = videoRefs.current.get(initialVideo.id);
              
              if (player) {
                
                player.playAsync();
              }
            }
          }
        }
      } catch (error) {
        console.error('Failed to load videos:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadVideos();
  }, [videoIds, initialVideoId]);
  
  useEffect(() => {
    const scrollToSelectedVideo = () => {
      
      if (initialVideoId && videos.length > 0) {
        const initialIndex = videos.findIndex(video => video.id === initialVideoId);
        
        if (initialIndex !== -1) {
          flatListRef.current?.scrollToIndex({animated: false, index: initialIndex});
        }
      }
    };
  
    if (!isLoading) {
      scrollToSelectedVideo();
    }
  }, [initialVideoId, videos, isLoading]);

  const isFocused = useIsFocused();

  useEffect(() => {
    const player = videoRef.current;
    if(isFocused){
      if (player) {
        player.playAsync();
      }
    } else {
      if (player) {
        player.pauseAsync();
      }
    }
  }, [isFocused]);

  
  
  
  

  return (
    <View style={styles.quizContainer}>
      <FlatList
          data={videos}
          
          ref={flatListRef}
          pagingEnabled={true}
          renderItem={renderItem}
          keyExtractor={item => item.id}
          getItemLayout={getItemLayout}
          onScrollToIndexFailed={scrollToIndexFailed}
          showsVerticalScrollIndicator={false}
          
        />
 
    </View>
  );
};



export default QuizViewerProfile;