import { StyleSheet, Dimensions } from "react-native";

const styles = StyleSheet.create({
  backgroundContainer: {
    paddingHorizontal: 10, // Add some horizontal padding
    paddingVertical: 5, // Add some vertical padding
    backgroundColor: 'rgba(255, 255, 255, 1)', // Semi-transparent background
    borderRadius: 10, // Rounded corners
    shadowColor: '#000', // Shadow for depth
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginBottom: 10, // Add some space below the container
  },
  container: {
    padding: 20, // Increase padding to create a larger touch area
    justifyContent: 'center',
    alignItems: 'center',
  },
  touchOverlay: {
    position: 'absolute',
    top: -10, // Extend the touch area beyond the visible scrubber
    bottom: -10,
    left: -10,
    right: -10,
    zIndex: 1, // Make sure this overlay is responsive to touch
    backgroundColor: 'transparent', // Keep the overlay invisible
  },
  markerContainer: {
    position: 'absolute',
    height: '100%',
    width: 20,  // Increased width for better touch
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  marker: {
    height: '150%',
    width: 4,
    backgroundColor: 'red',
  },
});

export default styles;
