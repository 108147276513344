import { StyleSheet } from "react-native";
import { Dimensions } from "react-native";

const styles = StyleSheet.create({
    container: {
      flex: 1,
      padding: 0,
      paddingTop: 10,
      color: 'black',
      backgroundColor: 'black',
    },
    profileHeader: {
      alignItems: 'center',
      marginBottom: 16,
    },
    profileDetails: {
      alignItems: 'center',
      marginTop: 100, // Increase this margin to push the username and bio down
    },
    profileImageWrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 8,
    },
    profileImageContainer: {
      position: 'absolute', // Position the avatar absolutely
      top: 10, // Distance from the top of the header
      left: 0,
      right: 0,
      alignItems: 'center', // This will center the avatar
      zIndex: 1, // Make sure it's in front of the bio
    },
    imageWrapper: {
      position: 'relative',
    },
    avatar: {
      width: 100,
      height: 100,
      borderRadius: 50,
    },
    cameraIcon: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      borderRadius: 50,
      padding: 5,
    },
    logoutContainer: {
      // Adjust styles as needed for the logout button's position
    },
    username: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 8,
      color: 'white'
    },
    bio: {
      fontSize: 14,
      marginBottom: 8,
      color: 'white'
    },
    bioInput: {
      backgroundColor: '#e0e0e0',
      padding: 10,
      marginVertical: 8,
      borderRadius: 5,
    },  
    countsContainer: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      padding: 10,
    },
    clipImageContainer: {
      width: 200, // Fixed width for each item, adjust as needed
      maxWidth: Dimensions.get('window').width / 3, // One clip per row
      aspectRatio: 1,
      padding: 2,
    },
    editButton: {
      marginBottom: 10,
      alignItems: 'center',
      backgroundColor: '#1DA1F2',
      alignContent: 'center',
      borderRadius: 50
    },
    infoButton: {
      alignSelf: 'flex-end', // Align to the top-left corner
      marginRight: 50,
    },
    clipImage: {
      width: '100%',
      height: '100%',
      resizeMode: 'cover',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between', // This will place the children at both ends and center
      alignItems: 'center',
      paddingHorizontal: 10, // Add some padding to the sides
      width: '100%', // Make sure the header is full-width
    },
    logoutButton: {
      alignSelf: 'flex-start', // Align to the top-right corner
      marginLeft: 50,
    },
    bioContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      zIndex: 0, // Ensure the bio is behind the avatar
      marginTop: 0, // Adjust the top margin to move it below the avatar
    },
    button: {
      marginTop: 8,
      padding: 8,
      backgroundColor: 'white',
      alignItems: 'center',
      borderRadius: 5,
    },
  });

  export default styles;