
import { useState, useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { LoginStackParamList } from '../../navigators/LoginNavigator';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { signInWithEmailAndPassword } from "firebase/auth";
import firebase from '../../firebase'; // Adjust the path as necessary
import { useAuth } from '../../context/AuthContext'; // Adjust the path as necessary
import { useDeepLink } from '../../context/DeepLinkContext'; // Adjust the path as necessary
import { useCustomAlert } from '../useCustomAlert';
import { sendPasswordResetEmail } from 'firebase/auth';
import axios from 'axios';

type User = {
    username: string;
    bio: string;
    followingUserIds: string[];
    watchedVideos: string[];
    blockedUserIds: string[];
    // Add any other user profile fields you expect
  };

  import { API_URL } from '@env';

/**
 * Custom hook for handling user authentication.
 * Provides functions for login, fetching user data, and managing custom alerts.
 * @returns An object containing the loading state, login handler, custom alert functions, and alert state.
 */
export const useAuthentication = () => {
  const [loading, setLoading] = useState(false);
  const { setIsAuthenticated, setUserProfile } = useAuth();
  const { deepLinkUrl, setDeepLinkUrl } = useDeepLink();
  const navigation = useNavigation<StackNavigationProp<LoginStackParamList>>();
  const { showCustomAlert, hideCustomAlert } = useCustomAlert();

  // States for managing CustomAlert
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  /**
   * Fetches and updates the top users data.
   * Makes an HTTP GET request to the backend API to fetch the top users data,
   * and stores the data in AsyncStorage.
   */
  const fetchAndUpdateTopUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/users/topMonthlyLikes/getTopUsers`);
      const topUsers = response.data;

      await AsyncStorage.setItem('topUsersData', JSON.stringify({
          topUsers: topUsers,
          lastFetched: new Date().toISOString(),
      }));

    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            // This is an error with the HTTP request
            const status = error.response?.status;
            const statusText = error.response?.statusText || 'No additional error message provided';
            const errorMessage = error.response?.data?.message || 'Error occurred during the request';
            console.error(`HTTP Error: ${status} ${statusText}. Message: ${errorMessage}`);
        } else {
            // This is some other error (like a network error)
            console.error('Non-HTTP Error:', error.message);
        }
    }
  };

  /**
   * Handles the login process.
   * Logs in the user using Firebase authentication, fetches user data from the backend,
   * and updates the user profile and top users data.
   * @param email The user's email address.
   * @param password The user's password.
   */
  const handleLogin = async (email: string, password: string) => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(firebase.auth, email, password);
      
      const userId = userCredential.user.uid;

      await AsyncStorage.setItem('userId', userId);
      const idToken = await userCredential.user.getIdToken();
      const currentTime = new Date().getTime();
      await AsyncStorage.setItem('idToken', JSON.stringify({ idToken, timestamp: currentTime }));

      // Backend call to fetch user data
      const userInfoResponse = await axios.get(`${API_URL}/users/${userId}`);
      if (userInfoResponse.data) {
          const userProfile: User = userInfoResponse.data;
          setUserProfile(userProfile);
          fetchAndUpdateTopUsers();

          await axios.post(
          `${API_URL}/users/get-login-details/${userProfile.username}`,
          {}, // This is where the request body should go. Use an empty object since there's no body.
          {
              headers: {
              'Authorization': `Bearer ${idToken}`,
              },
          }
          );

          // Convert userProfile object to a string before storing it
          const userProfileString = JSON.stringify(userProfile);
          await AsyncStorage.setItem('userToken', userProfileString);
          
          setIsAuthenticated(true);
          if(deepLinkUrl){

          const urlParts = deepLinkUrl.split('/');
          const videoIds = urlParts[urlParts.length - 3]; // Get videoIds from URL
          const initialVideoId = urlParts[urlParts.length - 2]; // Get initialVideoId from URL
          const username = urlParts[urlParts.length - 1]; // Get username from URL

          // Navigate to QuizViewerProfile with extracted parameters
          navigation.navigate('QuizViewerProfile', {
              videoIds: [videoIds],
              initialVideoId: initialVideoId,
              username: username
          });

          // Reset deep link URL to prevent repeated navigation
          setDeepLinkUrl('');
          }
      }
    } 
    catch (error: any) 
    {
        const errorMessage = error.code === 'auth/wrong-password' 
            ? 'The password is incorrect. Please try again.' 
            : error.message;
        showCustomAlert('Login Failed', errorMessage);
        console.error('Error during login:', error);
        setAlertTitle('Login Failed');
        setAlertMessage(error.message || 'An unexpected error occurred');
        setAlertVisible(true);  // Show the alert with the error message
    } 
    finally 
    {
        setLoading(false);
    }
  };

  const isValidEmail = (email: string) => {
    // Simple regex for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleForgotPassword = async ( email: string ) => {
    if (!email || !isValidEmail(email)) 
    {
      setAlertTitle('Invalid Email');
      setAlertMessage('Please enter a valid email address to reset your password.');
      setAlertVisible(true);
      return;
    }
    try 
    {
      await sendPasswordResetEmail(firebase.auth, email);

      setAlertTitle('Check Your Email');
      setAlertMessage('If your account is valid, a link to reset your password will be sent.');
      setAlertVisible(true);
    } catch (error: any) {
      setAlertTitle('Error');
      setAlertMessage(error);
      setAlertVisible(true);
      console.error('Error sending password reset email:', error);
    }
  };

  return {
      // Login state and functions
      loading,
      handleLogin,
      showCustomAlert,
      hideCustomAlert,
      alertVisible,
      setAlertVisible,
      alertMessage,
      alertTitle,
      isValidEmail, 
      handleForgotPassword
    };
};